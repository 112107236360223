import SideBlockAds from "@container/SideBlockAds";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import VideoPlayer from "@elements/Player/VideoPlayer";
import { isArrayWithElements } from "@helper/utils";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { PageAdTargetValue } from "@typings/Ads.d";

type VideoPageOldProps = {
  playing: LatestVideoResponse | undefined;
  containerRef: React.RefObject<HTMLDivElement>;
  videos: LatestVideoResponse[] | undefined;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  handleOnEndedVideo: () => void;
  handleOnClickThumbnail: (props: LatestVideoResponse) => void;
  handleLoadMoreVideo: () => Promise<number>;
};

export default function VideoPageOld(props: VideoPageOldProps) {
  const {
    playing,
    containerRef,
    videos,
    sectionName,
    pageAdTargetValue,
    handleOnEndedVideo,
    handleOnClickThumbnail,
    handleLoadMoreVideo,
  } = props;
  return (
    <div className="max-w-screen-cxl m-auto">
      <div className="flex flex-col lg:flex-row">
        <div className="flex lg:px-sm flex-col w-full">
          <span className="font-secondary text-blue-300 text-lg font-semimedium">
            Video
          </span>
          {playing?.id && (
            <VideoPlayer
              playing={playing}
              forwadedRef={containerRef}
              onEnded={handleOnEndedVideo}
              nextPlaylist={handleOnEndedVideo}
            />
          )}
          <div className="w-full border-b border-grey-850 pt-sm">
            <span className="font-secondary text-md lg:text-lg text-grey-400">
              Semua Video
            </span>
          </div>
          {isArrayWithElements(videos) &&
            videos.map((video, index) => (
              <ThumbnailCard<LatestVideoResponse>
                key={index}
                handleOnClickThumbnail={handleOnClickThumbnail}
                variant="video"
                {...video}
              />
            ))}
          <div className="my-md">
            <LoadMore
              onLoadMore={handleLoadMoreVideo}
              loadText="Laporan lain videos"
            />
          </div>
        </div>
        <SideBlockAds
          adsSectionName={sectionName}
          adsOnly={true}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
    </div>
  );
}
