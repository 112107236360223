import fetchBHApi from "@helper/fetchBHApi";
import { SHOW_SUB_CATEGORIES } from "@helper/getEnvVariables";
import useSwrApi from "@hooks/useSwrApi";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import VideoMetaData from "./VideoMetaData";
import VideoPageNew from "./VideoPageNew";
import VideoPageOld from "./VideoPageOld";

export default function Video() {
  let videoApi = "latest-videos";
  if (parseInt(SHOW_SUB_CATEGORIES)) {
    videoApi = "latest-videos?size=8";
  }
  const { data } = useSwrApi<LatestVideoResponse[]>(videoApi);
  const { level1: id } = useParams();
  const [offset, setOffset] = useState(parseInt(SHOW_SUB_CATEGORIES) ? 8 : 10);
  const [playlistMode, setPlaylistMode] = useState(false);
  const [playlistVideos, setPlaylistVideos] = useState<LatestVideoResponse[]>();
  const [videos, setVideos] = useState<LatestVideoResponse[]>();
  const [playing, setPlaying] = useState<LatestVideoResponse | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      if (!data || data.length === 0) return;
      let nowPlaying = false;
      try {
        let videos: LatestVideoResponse[];
        if (id) {
          videos = await fetchBHApi<LatestVideoResponse[]>(
            `latest-videos/${id}`,
            "GET",
          );
        } else {
          videos = await fetchBHApi<LatestVideoResponse[]>(
            "latest-videos-playlist",
            "GET",
          );
          setPlaylistMode(true);
          setPlaylistVideos(videos);
        }

        setPlaying(videos[0]);
        nowPlaying = id ? false : true;
      } catch (error) {
        nowPlaying = true;
        const playlist = await fetchBHApi<LatestVideoResponse[]>(
          "latest-videos-playlist",
          "GET",
        );
        setPlaylistVideos(playlist);
        setPlaying(playlist[0]);
      }

      const updatedVideos = data.map((video) =>
        video.id === playing?.id
          ? { ...video, nowPlaying: nowPlaying ? true : false }
          : video,
      );
      setVideos(updatedVideos);
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleLoadMoreVideo = async () => {
    let moreVideoApi = `latest-videos?offset=${offset}`;
    if (parseInt(SHOW_SUB_CATEGORIES)) {
      moreVideoApi = `latest-videos?size=8&offset=${offset}`;
    }
    const moreVideos = await fetchBHApi<LatestVideoResponse[]>(
      moreVideoApi,
      "GET",
    );
    setVideos((prevVideos) => [...(prevVideos || []), ...moreVideos]);

    if (parseInt(SHOW_SUB_CATEGORIES)) {
      setOffset((prevSize) => prevSize + 8);
    } else {
      setOffset((prevSize) => prevSize + 10);
    }

    // This avoids GTM push.
    return 0;
  };

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setVideos(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setVideos],
  );

  const handleOnClickThumbnail = useCallback(
    (props: LatestVideoResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setPlaylistMode(false);
    },

    [handleNowPlaying, setPlaying],
  );

  const sectionName = "videos";
  const { gaData, metaTags } = VideoMetaData(sectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
    ...(parseInt(SHOW_SUB_CATEGORIES) === 1 && {
      hideScrollableSectionMenu: true,
    }),
  };

  const handleOnEndedVideo = () => {
    if (!playlistMode) return;
    if (!playlistVideos) return;
    let index: number = playlistVideos.findIndex(
      (video) => video.id === playing?.id,
    );
    if (index++ === playlistVideos.length - 1) {
      setPlaying(playlistVideos[0]);
    } else {
      setPlaying(playlistVideos[index++]);
    }
  };

  const renderVideoPage = () => {
    if (parseInt(SHOW_SUB_CATEGORIES)) {
      return (
        <VideoPageNew
          playing={playing}
          containerRef={containerRef}
          videos={videos}
          sectionName={sectionName}
          pageAdTargetValue={pageAdTargetValue}
          handleOnEndedVideo={handleOnEndedVideo}
          handleOnClickThumbnail={handleOnClickThumbnail}
          handleLoadMoreVideo={handleLoadMoreVideo}
        />
      );
    }

    return (
      <VideoPageOld
        playing={playing}
        containerRef={containerRef}
        videos={videos}
        sectionName={sectionName}
        pageAdTargetValue={pageAdTargetValue}
        handleOnEndedVideo={handleOnEndedVideo}
        handleOnClickThumbnail={handleOnClickThumbnail}
        handleLoadMoreVideo={handleLoadMoreVideo}
      />
    );
  };

  return <BHBasePage {...basePageProps}>{renderVideoPage()}</BHBasePage>;
}
