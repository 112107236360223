import celebrateAnimationData from "@assets/wordpuzzle/new-icons/celebration.json";
import DesktopLogo from "@assets/wordpuzzle/new-icons/Desktop_celebrate_logo.svg";
import MobileLogo from "@assets/wordpuzzle/new-icons/Mobile_celebrate_logo.svg";
import SmillingFaceWithSunGlass from "@assets/wordpuzzle/smiling-face-with-sunglasses.svg";
import { lazy } from "react";
// Lazy load Lottie powered animation to avoid including heavy library and animation data in main bundle
// - currently this means the animation will be loaded client-side only
const CelebrateAnimation = lazy(
  () => import("@components/AnimationPlayer/AnimationPlayer"),
);

export default function CelebrateResult() {
  return (
    <div
      className="relative flex flex-col lg:flex-row bg-white-300 lg:justify-center items-center h-[195px] lg:h-[160px] gap-md lg:gap-xl"
      style={{ boxShadow: "0px -4px 8px 0px rgba(69, 69, 69, 0.20)" }}
    >
      <div className="absolute bottom-[195px] w-[375px] h-[236px] lg:bottom-[160px] lg:w-[690px] lg:h-[434px]">
        <CelebrateAnimation src={celebrateAnimationData} />
      </div>
      <div className="hidden lg:flex w-[433px] h-[169px] relative">
        <div className="absolute -top-[2px] -left-md">
          <img
            src={DesktopLogo}
            alt="main-logo"
            className="w-[433px] h-[169px]"
          />
        </div>
      </div>
      <div className="flex lg:hidden">
        <img src={MobileLogo} alt="mobile-logo" />
      </div>
      <div className="flex flex-col items-center text-sm font-semibold text-grey-800">
        <div>
          <img
            src={SmillingFaceWithSunGlass}
            alt="hebat"
            width={40}
            height={40}
          />
        </div>
        <span>Hebat! Percubaan yang cemerlang</span>
        <span>Markah anda: 3 out of 3</span>
      </div>
    </div>
  );
}
