import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { RefObject } from "react";
import { twMerge } from "tailwind-merge";

type CardStyles = {
  wrapperClass?: string;
  nameClass?: string;
  descriptionClass?: string;
  dateClass?: string;
};

type Props = {
  playing: LatestVideoResponse;
  forwadedRef: RefObject<HTMLDivElement>;
  onEnded?: () => void;
  nextPlaylist?: () => void;
  showDescription?: boolean;
  cardStyle?: CardStyles;
  variant?: "video" | "webinar";
};

export default function VideoPlayerSticky({
  playing,
  forwadedRef,
  onEnded,
  nextPlaylist,
  showDescription = false,
  cardStyle = {},
  variant = "video",
}: Props) {
  const { id, name, topStoryDate, description, published_at, webinar_time } =
    playing;
  const { wrapperClass, nameClass, descriptionClass, dateClass } = cardStyle;
  return (
    <div
      className="flex flex-col lg:flex-row gap-[10px] 
      lg:max-w-screen-lg xl:max-w-screen-cxl mx-auto px-[10px] lg:px-[5px]
      sticky top-[40px] lg:top-[119px] z-10 bg-white-100 border-b-2 border-grey-100 pb-[18px] pt-[17px] lg:pt-[20px]"
    >
      <div className="lg:w-[72%]">
        <BrightcoveVideo
          videoId={id}
          forwardedRef={forwadedRef}
          onEnded={onEnded}
          nextPlaylist={nextPlaylist}
        />
      </div>
      <div className="flex flex-col font-secondary lg:w-[28%]">
        <h3
          className={twMerge(
            "font-medium text-[22px] text-black-800 leading-[130%]",
            nameClass,
          )}
        >
          {name}
        </h3>
        {showDescription && (
          <span
            className={twMerge(
              "my-2 hidden lg:block text-xxs font-[500] font-primary lg:text-xs text-grey-350",
              descriptionClass,
            )}
          >
            {description}
          </span>
        )}
        <div
          className={twMerge("flex items-center justify-between", wrapperClass)}
        >
          <span
            className={twMerge(
              "text-xxs font-[500] font-primary lg:text-xs text-black-700",
              dateClass,
            )}
          >
            {variant === "webinar" ? (
              <>
                {published_at}{" "}
                <span className="font-thin">- {webinar_time}</span>
              </>
            ) : (
              topStoryDate
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
