import {
  adSlotSizes,
  lb1AdSlotSizesInfinteScroll,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import { stripeAdsBackground } from "@elements/Advertisement/Advertisement";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 * @index - use when lb1 is rendered more than 1
 */
export function CompanionAdsLb({
  uniqueSectionName,
  index,
  stripeBackground = true,
  pageAdTargetType,
  gsChannels,
  articleSlotTarget,
  overRideSlot = true,
  rootClassName,
  wrapperStyle,
  applySizeMapping = false,
}: {
  uniqueSectionName?: string;
  index: number;
  wrapperStyle?: string;
  stripeBackground?: boolean;
  pageAdTargetType?: PageAdTargetValue;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  overRideSlot?: boolean;
  rootClassName?: string;
  applySizeMapping?: boolean;
}) {
  let overrideLb1Slot = lb1AdSlotSizesInfinteScroll["desktop"];
  if (typeof window !== "undefined" && screen.width < 767) {
    overrideLb1Slot = lb1AdSlotSizesInfinteScroll["mobile"];
  }

  const pageAdTarget =
    pageAdTargetType &&
    (pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
      string,
      PageAdTargetValue
    >);
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        wrapperStyle,
      )}
      style={stripeBackground ? stripeAdsBackground : undefined}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB2,
          slot: GoogleAdsSlotFactory.lb2(uniqueSectionName),
          id: `dfp-ad-lb2-${index}`,
          rootClassName: rootClassName ? rootClassName : "m-0",
          pageNumber: index.toString(),
          ...(pageAdTarget && { pubAdTargeting: [pageAdTarget] }),
          gsChannels,
          articleSlotTarget,
          ...(overRideSlot
            ? { adSlotSize: overrideLb1Slot }
            : { adSlotSize: adSlotSizes["lb2"] }),
          applySizeMapping,
        }}
      />
    </div>
  );
}
