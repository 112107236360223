import { SectionDetails } from "@components/HeaderNew/headerMenuConstants";
import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { SHOW_SUB_CATEGORIES } from "@src/app/helper/getEnvVariables";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { HeaderMenuType } from "./headerMenuTypes";

type HeaderMenuListProps = {
  testId: string;
  items: SectionDetails[];
  type: HeaderMenuType;
  addActiveColor?: boolean;
};

export function HeaderMenuList({
  testId,
  items,
  type,
  addActiveColor = false,
}: HeaderMenuListProps) {
  const { pathname } = useLocation();

  const isActive = (location: string): boolean => {
    const pathnameOnly = location.split("?")[0];
    const sectionName = pathname.split("/")[1];
    if (
      parseInt(SHOW_SUB_CATEGORIES) === 1 &&
      (sectionName === "podcasts" || sectionName === "videos")
    ) {
      return pathnameOnly === "/podcasts";
    }
    return pathname === pathnameOnly || `/${sectionName}` === pathnameOnly;
  };

  return (
    <List
      testId={`${testId}-${type}-menuList`}
      style={cx(
        type === "topics" &&
          "flex items-center justify-center gap-md lg:gap-xs xl:gap-sm",
        type === "secTopics" &&
          "flex items-center justify-center lg:gap-sm xl:gap-md",
        type === "misc" && "flex items-center justify-center",
      )}
    >
      {items.map((item: SectionDetails, index: number) => (
        <ListItem
          key={`${testId}-item-${index}`}
          testId={`${testId}-item-${index}`}
          className={cx(
            "cursor-pointer",
            type === "topics" &&
              "flex items-center justify-center text-sm lg:text-xs lg:font-regular lg:leading-[35px] lg:w-[71px] xl:w-[78px] whitespace-nowrap",
            (type === "topics" || type === "secTopics") &&
              item.title === "Bahasa & Budaya" &&
              "lg:!w-[105px] xl:!w-[115px]",
            (type === "topics" || type === "secTopics") &&
              (item.title === "Videos" ||
                item.title === "Gen G" ||
                item.title === "Sukan" ||
                item.title === "Wacana" ||
                item.title === "Ekonomi" ||
                item.title === "Kuiz BH" ||
                item.title === "Podcast" ||
                item.title === "Dunia") &&
              "lg:!w-[53px] xl:!w-[64px]",
            type === "secTopics" &&
              "flex items-center justify-center text-xs font-regular leading-[35px] lg:w-[70px]",
            type === "secTopics" &&
              item.title == "Akses Percuma" &&
              "lg:!w-[100px]",
            type === "topics" && item.title == "Multimedia" && "lg:!w-[90px]",
            (type === "topics" || type === "secTopics") &&
              item.title == "Pengguna Berdaftar" &&
              "lg:!w-[130px]",
            type === "misc" && "text-xxs text-grey-600 ",
            type === "mobileMain" &&
              "flex h-[54px] items-center [&_a]:text-white-500 [&_a]:text-sm [&_a]:font-semimedium [&_a]:w-full",
            type === "mobileSection" &&
              "flex h-[54px] items-center [&_a]:w-full [&_a]:text-sm [&_a]:font-semimedium",
            type === "mobileMisc" &&
              "flex h-[54px] [&_a]:text-white-500 [&_a]:text-sm [&_a]:font-semimedium [&_a]:w-full",
            type === "mobileSecondary" &&
              "flex h-[54px] [&_a]:text-gold-200 [&_a]:text-sm [&_a]:font-semimedium [&_a]:w-full",
          )}
        >
          <div
            className={`flex items-center gap-xxs min-w-max ${
              addActiveColor &&
              isActive(item.url) &&
              type === "secTopics" &&
              `border-b-[3px] border-${item.selectedColor}`
            } ${type === "misc" && "gap-0"}`}
          >
            <Link
              reloadDocument
              key={index}
              to={item.url}
              className={cx(
                type === "misc" && "font-regular text-blue-400",
                type === "topics" &&
                  "text-black-700 lg:text-white-500 transition-transform duration-300 ease-out hover:opacity-100 hover:font-semibold",
                type === "secTopics" &&
                  "text-white-500 transition-transform duration-300 ease-out hover:opacity-100 hover:font-semibold",
                type === "topics" || type === "secTopics"
                  ? isActive(item.url)
                    ? "font-semibold opacity-100"
                    : "font-semimedium opacity-90"
                  : "",
                type === "mobileSection" ? "text-white-500" : "",
              )}
              {...(item.newTab && { target: "_blank" })}
            >
              {item.title}
            </Link>
            {type === "misc" && (
              <span className="px-xxs text-grey-150 text-sm font-regular">
                |
              </span>
            )}
          </div>
        </ListItem>
      ))}
    </List>
  );
}
