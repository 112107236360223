import slideDownIconMobile from "@assets/header/slideDownIconMobile.svg";
import slideUpIconMobile from "@assets/header/slideUpIconMobile.svg";
import { ReactElement, useState } from "react";

import { SectionDetails } from "./headerMenuConstants";

type MobileMenuWithSubCategoriesProps = {
  menuItems: Record<string, SectionDetails[]>;
  rootClassName?: string;
};

export default function MobileMenuWithSubCategories({
  menuItems,
  rootClassName,
}: MobileMenuWithSubCategoriesProps): ReactElement {
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className={rootClassName}>
      <ul className="flex flex-col">
        {Object.entries(menuItems).map(([sectionTitle, subSections]) => (
          <li
            key={sectionTitle}
            className={
              openSections[sectionTitle]
                ? "border-black-800 border-b-2"
                : "flex items-center h-[54px]"
            }
          >
            {/* Show collapsible only if there are multiple subsections */}
            {subSections.length > 1 ? (
              <>
                <button
                  className={`flex w-full items-center justify-between px-4 text-white-500 
                    ${openSections[sectionTitle] ? "bg-grey-350 py-4" : ""}
                    `}
                  onClick={() => {
                    toggleSection(sectionTitle);
                  }}
                >
                  <span className="text-sm font-semimedium">
                    {sectionTitle}
                  </span>
                  <img
                    src={
                      openSections[sectionTitle]
                        ? slideUpIconMobile
                        : slideDownIconMobile
                    }
                    alt={openSections[sectionTitle] ? "Collapse" : "Expand"}
                    width={14}
                    height={14}
                  />
                </button>

                {/* Collapsible Subsections */}
                {openSections[sectionTitle] && (
                  <ul className="flex flex-col gap-[20px] bg-grey-350 text-white-500 px-[31px] pb-4">
                    {subSections.map((subSection, index) => (
                      <li key={index} className="">
                        <a
                          href={subSection.url}
                          className="block text-sm font-semimedium"
                        >
                          {subSection.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              // Direct link if only one subsection
              <a
                href={subSections[0]?.url}
                className="block w-full px-4 text-white-500 text-sm font-semimedium"
              >
                {sectionTitle}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
