import WebinarCarousel from "@components/Carousel/WebinarCarousel";
import Heading from "@elements/Typography/Heading";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import NoWebinar from "./NoWebinar";
import { WebinarPayloadResponseType } from "./Webinar.server";
import WebinarMetaData from "./WebinarMetaData";
import WebinarRecordedVideo from "./WebinarRecordedVideo";

export default function Webinar() {
  const routeContext: CustomContext<
    TRouteWithRedirect<WebinarPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  if (dataLoaderResponse.type === ResponseType.SUCCESS) {
    const data = dataLoaderResponse.payload.data;
    const uniqueSectionName = "webinar";
    const { gaData, metaTags } = WebinarMetaData(uniqueSectionName);
    const basePageProps: BHBasePageProps = {
      uniqueSectionName,
      pageAdTargetValue: PageAdTargetingTypeEnum.LISTING,
      gaData,
      metaTags,
    };

    return (
      <BHBasePage {...basePageProps}>
        <WidgetWrapper>
          {data.length === 0 ? (
            <NoWebinar />
          ) : (
            <>
              <Heading
                Element="h1"
                className="text-blue-100 text-lg font-semimedium leading-[31px] mb-2"
              >
                Webinar Akan Datang
              </Heading>
              <WebinarCarousel renderItems={data} />
            </>
          )}
          <WebinarRecordedVideo />
        </WidgetWrapper>
      </BHBasePage>
    );
  }
}
