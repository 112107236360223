import { adSlotSizes } from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

type TranslationAdsImuProps = {
  uniqueSectionName?: string;
  rootClassName?: string;
  index: number;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

export function TranslationAdsImu1({
  uniqueSectionName,
  rootClassName,
  index,
  gsChannels,
  articleSlotTarget,
}: TranslationAdsImuProps) {
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(uniqueSectionName),
        id: index === 0 ? "dfp-ad-imu1-trans" : `dfp-ad-imu1-trans-${index}`,
        adSlotSize: adSlotSizes["imu1"],
        pageNumber: index.toString(),
        gsChannels,
        articleSlotTarget,
      }}
    />
  );
}

export function TranslationAdsMidArticle({
  uniqueSectionName,
  rootClassName,
  index,
  gsChannels,
  articleSlotTarget,
}: TranslationAdsImuProps) {
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("block text-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
        slot: GoogleAdsSlotFactory.midarticlespecial(uniqueSectionName),
        id:
          index === 0
            ? "dfp-ad-midarticlespecial-trans"
            : `dfp-ad-midarticlespecial-trans-${index}`,
        adSlotSize: adSlotSizes["midarticlespecial"],
        pageNumber: index.toString(),
        gsChannels,
        articleSlotTarget,
        rootClassName: "",
      }}
    />
  );
}
