import FoodMapDesktop from "@components/FoodMap/FoodMapElements/FoodMapDesktop";
import FoodMapGoogleMaps from "@components/FoodMap/FoodMapGoogleMaps";
import FoodMapLocationPermission from "@components/FoodMap/FoodMapLocationPermission";
import { isLocationInSingapore, roundToFourDecimals } from "@helper/utils";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { useVirtualPVAnalyticsCall } from "@hooks/useAnalyticsCall/useVirtualPVAnalyticsCall";
import useRemoveOverlay from "@hooks/useRemoveOverlay";
import useUserLocation from "@hooks/useUserLocation/useUserLocation";
import useWatchPosition from "@hooks/useUserLocation/useWatchPosition";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  ResponseType,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { Point, PositionError } from "@typings/foodMapTypes";
import { useEffect, useState } from "react";

import type { apiKeyParams } from "./FoodMap.server";
import FoodMapMetaData from "./FoodMapMetaData";
import styles from "./FoodMapPage.module.css";

export function FoodMapPage() {
  const [userLocation, setUserLocation] = useState<Point | undefined>();
  const [watchLocation, setWatchLocation] = useState<Point>();
  const [geoError, setGeoError] = useState<
    PositionError | GeolocationPositionError
  >();
  const [initLocation, setInitLocation] = useState<boolean>(false);
  const [startWatching, setStartWatching] = useState(false);
  useRemoveOverlay();
  const { location, error, requestLocation, loading } = useUserLocation();
  const { userPosition, watchError } = useWatchPosition(startWatching);
  const bhCustomEventAnalyticsCall = useBHCustomEventAnalyticsCall();
  const virtualPVAnalyticsCall = useVirtualPVAnalyticsCall();

  useEffect(() => {
    if (!initLocation) {
      if (location) {
        if (!isLocationInSingapore(location)) {
          setWatchLocation(location);
          setGeoError({
            code: 404,
            message: "Location out of Singapore",
          });
        } else {
          setUserLocation(location);
          setWatchLocation(location);
          setInitLocation(true);
          setStartWatching(true);
          bhCustomEventAnalyticsCall("foodmap", "initialize", undefined, false);
          virtualPVAnalyticsCall(
            "/foodmap",
            "Food Map List",
            `${location.lat}, ${location.lng}`,
          );
        }
      } else {
        if (error) {
          setGeoError(error);
        }
      }
    }
  }, [
    initLocation,
    location,
    error,
    bhCustomEventAnalyticsCall,
    virtualPVAnalyticsCall,
  ]);

  useEffect(() => {
    const roundedUserLat = userPosition
      ? roundToFourDecimals(userPosition.lat)
      : null;
    const roundedUserLng = userPosition
      ? roundToFourDecimals(userPosition.lng)
      : null;
    const roundedWatchLat = watchLocation
      ? roundToFourDecimals(watchLocation.lat)
      : null;
    const roundedWatchLng = watchLocation
      ? roundToFourDecimals(watchLocation.lng)
      : null;

    if (
      userPosition &&
      watchLocation &&
      (roundedUserLat !== roundedWatchLat || roundedUserLng !== roundedWatchLng)
    ) {
      if (!isLocationInSingapore(userPosition)) {
        setWatchLocation(userPosition);
        setGeoError({
          code: 404,
          message: "Location out of Singapore",
        });
      } else {
        setUserLocation(userPosition);
        setWatchLocation(userPosition);
        virtualPVAnalyticsCall(
          "/foodmap",
          "Food Map List",
          `${userPosition.lat}, ${userPosition.lng}`,
        );
      }
    } else {
      if (watchError) {
        setGeoError(watchError);
        setUserLocation(undefined);
      }
    }
  }, [userPosition, watchLocation, watchError, virtualPVAnalyticsCall]);

  const routeContext: CustomContext<
    TRouteWithoutRedirect<apiKeyParams, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const apiKey = dataLoaderResponse.payload.apiKey;

  return (
    <>
      <FoodMapMetaData />
      <div
        className={`block fixed lg:static top-0 w-full h-full ${styles.foodmap}`}
      >
        <FoodMapDesktop />
        <div className={`flex flex-col lg:hidden max-w-[820px]`}>
          {userLocation !== undefined ? (
            <FoodMapGoogleMaps
              foodMapWrapper="flex"
              userLocation={userLocation}
              apiKey={apiKey}
            />
          ) : (
            <>
              {location == null && (
                <div className="flex flex-col items-center gap-3 p-3">
                  <p className="text-center text-md text-black-300">
                    We need to access your location to recommend halal food
                    eateries near you.
                  </p>
                  <button
                    onClick={requestLocation}
                    disabled={loading}
                    className={`${loading ? "bg-blue-100" : "bg-blue-400"} text-white-100 p-[6px] border-[1px] border-white-100 rounded-[4px]`}
                  >
                    {loading ? "Getting Location..." : "Allow Location Access"}
                  </button>
                </div>
              )}
              {geoError && <FoodMapLocationPermission error={geoError} />}
            </>
          )}
        </div>
      </div>
    </>
  );
}
