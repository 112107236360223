/**
 * Company: SPHMedia
 * Description: [GE Landing page button]
 */

import GEButtonIcon from "@assets/ge2025/ge-rectangle-home.svg";
import GEButtonIconHover from "@assets/ge2025/ge-rectangle-home-hover.svg";
import Link from "@elements/Typography/Link";
import { ReactElement } from "react";

type GELandingPageButtonProps = {
  GEPagePath: string;
};

/**
 * GELandingPageButton
 * Description: [GE button for homepage]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GELandingPageButton({
  GEPagePath,
}: GELandingPageButtonProps): ReactElement {
  return (
    <Link destination={GEPagePath} className="flex items-center group">
      <span
        className="
        flex items-center justify-center w-[286px] xl:w-[345px]
        text-[15px] text-white-100 font-semibold font-primary bg-red-100 group-hover:bg-red-800 h-[34px]"
      >
        Dapatkan liputan penuh GE2025 di sini
      </span>
      <img
        alt="ge-button-icon"
        src={GEButtonIcon}
        className="group-hover:hidden"
        width={15}
        height={34}
        style={{
          height: "34px",
          marginLeft: "-1px",
        }}
      />
      <img
        alt="ge-button-icon-hover"
        src={GEButtonIconHover}
        className="hidden group-hover:block"
        width={14}
        height={34}
      />
    </Link>
  );
}
