import { SectionTitle } from "@components/Elements/Typography/Title";
import SubSectionNav from "@components/SubSectionNav/SubSectionNav";
import SideBlockAds from "@container/SideBlockAds";
import { SectionTitleV2 } from "@elements/Typography/TitleV2";
import { SHOW_SUB_CATEGORIES } from "@helper/getEnvVariables";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import type { SectionPayloadResponseType } from "./Section.server";
import SectionArticlesList from "./SectionArticlesList";
import { SectionMetaData } from "./SectionMetaData";
import SectionArticlesListNew from "./SectionPageLayout/SectionArticleListNew";

export function SectionPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<SectionPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const {
    data: sectionArticles,
    paywall,
    section: sectionName,
  } = dataLoaderResponse.payload;
  const parentSection = sectionArticles[0].parentSection;
  const { gaData, metaTags } = SectionMetaData({
    sectionName,
    parentSection,
    paywall,
  });

  const adsSectionName = !paywall
    ? sectionArticles[0].adsUniqueSectionName
    : sectionName;
  const sectionNameNavObj = !paywall
    ? sectionArticles[0].parentSection
      ? sectionArticles[0].uniqueParentSectionName
      : sectionArticles[0].uniqueSectionName
    : sectionName;

  const displaySectionName = !paywall
    ? parentSection
      ? parentSection
      : sectionArticles[0].sectionName
    : sectionName;
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    adsSectionName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
    ...(parseInt(SHOW_SUB_CATEGORIES) === 1 && {
      hideScrollableSectionMenu: true,
    }),
  };

  const renderListingPage = () => {
    if (parseInt(SHOW_SUB_CATEGORIES)) {
      return (
        <div className="m-auto">
          <WidgetWrapper>
            <SectionTitleV2 sectionName={displaySectionName} />
            <SubSectionNav
              sectionName={sectionNameNavObj || ""}
              displaySection={displaySectionName || ""}
            />
          </WidgetWrapper>
          <div className="flex flex-col lg:flex-row w-full gap-4">
            <SectionArticlesListNew
              sectionArticles={sectionArticles}
              sectionName={sectionName}
              adsSectionName={adsSectionName}
              paywall={paywall}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="max-w-screen-cxl m-auto">
        <SectionTitle sectionName={sectionName} />
        <div className="flex flex-col lg:flex-row w-full gap-4">
          <SectionArticlesList
            sectionArticles={sectionArticles}
            sectionName={sectionName}
            adsSectionName={adsSectionName}
            paywall={paywall}
          />
          <SideBlockAds
            adsSectionName={adsSectionName}
            className="max-w-[400px] py-xs lg:px-6 sticky top-[130px]"
            pageAdTargetingProps={pageAdTargetValue}
          />
        </div>
      </div>
    );
  };

  return <BHBasePage {...basePageProps}>{renderListingPage()}</BHBasePage>;
}
