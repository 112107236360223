import { SectionDetails } from "../Header/headerMenuConstants";
import {
  DUNIA_SUB_SECTION,
  EKONOMI_SUB_SECTION,
  GAYA_HIDUP_SUB_SECTION,
  MULTIMEDIA_SUB_SECTION,
  SINGAPURA_SUB_SECTION,
  WACANA_SUB_SECTION,
} from "../HeaderNew/subCategoriesConstants";

export const subSectionNavObj: Record<string, SectionDetails[]> = {
  singapura: SINGAPURA_SUB_SECTION,
  kesihatan: SINGAPURA_SUB_SECTION,
  dunia: DUNIA_SUB_SECTION,
  "ekonomi-kerja": EKONOMI_SUB_SECTION,
  "gaya-hidup": GAYA_HIDUP_SUB_SECTION,
  wacana: WACANA_SUB_SECTION,
  hidayah: WACANA_SUB_SECTION,
  podcasts: MULTIMEDIA_SUB_SECTION,
  videos: MULTIMEDIA_SUB_SECTION,
};

type TagsToSectionMapping = {
  displaySectionName: string;
  uniqueSectionName: string;
};

export const tagsSubSectionMapping: Record<string, TagsToSectionMapping> = {
  "Kaki Makan": {
    displaySectionName: "Gaya Hidup",
    uniqueSectionName: "gaya-hidup",
  },
  "Kaki Jalan": {
    displaySectionName: "Gaya Hidup",
    uniqueSectionName: "gaya-hidup",
  },
  "DNA Dunia Anak": {
    displaySectionName: "Gaya Hidup",
    uniqueSectionName: "gaya-hidup",
  },
};
