import SubSectionNav from "@components/SubSectionNav/SubSectionNav";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import VideoPlayerSticky from "@elements/Player/VideoPlayerSticky";
import { SectionTitleV2 } from "@elements/Typography/TitleV2";
import { displayAfter, isArrayWithElements } from "@helper/utils";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { PageAdTargetValue } from "@typings/Ads.d";

type VideoPageNewProps = {
  playing: LatestVideoResponse | undefined;
  containerRef: React.RefObject<HTMLDivElement>;
  videos: LatestVideoResponse[] | undefined;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  handleOnEndedVideo: () => void;
  handleOnClickThumbnail: (props: LatestVideoResponse) => void;
  handleLoadMoreVideo: () => Promise<number>;
};

const PAGE_SIZE = 8;

export default function VideoPageNew(props: VideoPageNewProps) {
  const {
    playing,
    containerRef,
    videos,
    sectionName,
    pageAdTargetValue,
    handleOnEndedVideo,
    handleOnClickThumbnail,
    handleLoadMoreVideo,
  } = props;

  const displaySectionName = "Multimedia";

  return (
    <>
      <WidgetWrapper>
        <SectionTitleV2 sectionName={displaySectionName} />
        <SubSectionNav
          sectionName={sectionName}
          displaySection={displaySectionName}
        />
      </WidgetWrapper>
      {playing?.id && (
        <VideoPlayerSticky
          playing={playing}
          forwadedRef={containerRef}
          onEnded={handleOnEndedVideo}
          nextPlaylist={handleOnEndedVideo}
          showDescription={true}
        />
      )}

      {isArrayWithElements(videos) &&
        videos.reduce((acc: JSX.Element[], _, index) => {
          // Insert an ad every PAGE_SIZE articles, outside the wrapper
          if (displayAfter(index, PAGE_SIZE)) {
            acc.push(
              <div key={`ad-${index}`} className="w-full bg-white-200">
                <CompanionAdsLb
                  uniqueSectionName={sectionName}
                  index={index / PAGE_SIZE}
                  pageAdTargetType={pageAdTargetValue}
                  stripeBackground={false}
                  overRideSlot={false}
                  applySizeMapping={true}
                />
              </div>,
            );
          }
          // Start a new batch every 8 articles
          if (index % PAGE_SIZE === 0) {
            acc.push(
              <WidgetWrapper key={`batch-${index}`} className="my-[20px]">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  {videos.slice(index, index + PAGE_SIZE).map((video, idx) => (
                    <ThumbnailCard<LatestVideoResponse>
                      key={`video-${index}-${idx}`}
                      handleOnClickThumbnail={handleOnClickThumbnail}
                      variant="video"
                      styles={{
                        buttonStyle: "flex",
                        wrapperStyle: "flex-col-reverse justify-end gap-2",
                      }}
                      {...video}
                    />
                  ))}
                </div>
              </WidgetWrapper>,
            );
          }

          return acc;
        }, [])}
      <div className="my-md">
        <LoadMore
          onLoadMore={handleLoadMoreVideo}
          loadText="Laporan lain videos"
        />
      </div>
    </>
  );
}
