import fetchBHApi from "@src/app/helper/fetchBHApi";

interface PuzzleData {
  [key: string]: string;
}

export const fetchPuzzleData = async (
  formattedDate: string,
): Promise<PuzzleData[] | null> => {
  try {
    const response = await fetchBHApi<PuzzleData[]>(
      `feeds/puzzle?date=${formattedDate}`,
      "GET",
    );
    return response;
  } catch (error) {
    console.error("Error fetching puzzle data:", error);
    return null;
  }
};
