export const NEURON_API_URL = import.meta.env.VITE_NEURON_API_URL as string;

export const NEURON_API_TOKEN = import.meta.env.VITE_NEURON_API_TOKEN as string;

export const MODE = import.meta.env.MODE;

export const NEW_RELIC_KEY = import.meta.env.VITE_NEW_RELIC_KEY as string;

export const LIVE_RAMP_PID = import.meta.env.VITE_LIVE_RAMP_PID as string;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | undefined;

export const CAS_API_KEY = import.meta.env.VITE_CDP_API_KEY as string;

export const CAS_ACTIVATION_URL = import.meta.env
  .VITE_CDP_ACTIVATION_URL as string;

export const CAS_UGC_SECTION_BANNER_ID = import.meta.env
  .VITE_CDP_UGC_ID as number;

export const CAS_CDP_HOME_ID = import.meta.env.VITE_CDP_HOME_ID as number;

export const BC_PLAYER_ID = import.meta.env.VITE_BC_PLAYER_ID as string;

export const MYSPH_LIGHBOX2_CLIENTID = import.meta.env
  .VITE_PUBLIC_MYSPH_LIGHBOX2_CLIENTID as string;
export const MYSPH_LIGHBOX2_JS = import.meta.env
  .VITE_PUBLIC_MYSPH_LIGHBOX2_JS as string;

export const BC_PLAYER_ID_SMALL_WIDGET = import.meta.env
  .VITE_BC_PLAYER_ID_SMALL_WIDGET as string;

export const SHOW_NEW_MASTHEAD = import.meta.env
  .VITE_SHOW_NEW_MASTHEAD as string;
export const SHOW_SUB_CATEGORIES = import.meta.env
  .VITE_SHOW_SUB_CATEGORIES as string;

export const INCLUDE_SMX_FORMAT_OPTIONS = import.meta.env
  .VITE_INCLUDE_SMX_FORMAT_OPTIONS as string;

export const HOME_STORY_QUEUE_ID = import.meta.env
  .VITE_HOME_STORY_QUEUE_ID as string;

export const CAS_EI_MODEL_ID = import.meta.env.VITE_CDP_EI_ID as number;

export const EMARSYS_URL = import.meta.env.VITE_EMARSYS_BASE_URL as string;

export const DRUPAL_API_ENDPOINT = import.meta.env
  .VITE_DRUPAL_API_ENDPOINT as string;

export const DRUPAL_BASE_URL = import.meta.env.VITE_DRUPAL_BASE_URL as string;

export const FB_AUTH_DOMAIN = import.meta.env.VITE_FB_AUTH_DOMAIN as string;
export const FB_DATABASE_URL = import.meta.env.VITE_FP_DATABASE_URL as string;
export const FB_PROJECT_ID = import.meta.env.VITE_FB_PROJECT_ID as string;
export const FB_STORAGE_BUCKET = import.meta.env
  .VITE_FB_STORAGE_BUCKET as string;
export const FB_MESSAGING_SENDER_ID = import.meta.env
  .VITE_FB_MESSAGING_SENDER_ID as string;
export const FB_APP_ID = import.meta.env.VITE_FB_APP_ID as string;
export const FB_TOKEN_TOPIC = import.meta.env.VITE_FB_TOKEN_TOPIC as string;
export const FB_CLIENT_EMAIL = import.meta.env.VITE_FB_CLIENT_EMAIL as string;
export const REACT_APP_URL = import.meta.env.VITE_REACT_APP_URL as string;
export const FB_MEASURMENT_ID = import.meta.env
  .VITE_FB_MEASUREMENT_ID as string;

export const CAS_CDP_PAYWALL_ID = import.meta.env.VITE_CDP_PAYWALL_ID as number;
export const CAS_CDP_REGWALL_ID = import.meta.env.VITE_CDP_REGWALL_ID as number;

export const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;

export const ADS_DOMAIN: string =
  ENVIRONMENT == "prd"
    ? "https://adtag.sphdigital.com"
    : "https://adtag-stg.sphdigital.com";

export const BH_BASE_URL: string =
  ENVIRONMENT === "prd"
    ? "https://www.beritaharian.sg/"
    : "https://uat.beritaharian.sg/";

export const EPAPER_LINK = import.meta.env.VITE_EPAPER_LINK as string;

export const ARTICLE_TRANSLATE_CTA = import.meta.env
  .VITE_ARTICLE_TRANSLATE_CTA as string;

export const ARTICLE_SUMMARY_CTA = import.meta.env
  .VITE_ARTICLE_SUMMARY_CTA as string;

export const WEBINAR_PLAYLIST_ID = import.meta.env
  .VITE_WEBINAR_PLAYLIST_ID as string;
export const WEBINAR_ESHOP_LINK = import.meta.env
  .VITE_WEBINAR_ESHOP_LINK as string;

export const GE_STORY_QUEUE_ID = import.meta.env
  .VITE_GE_STORY_QUEUE_ID as string;
export const GE_TAG_ALIAS = import.meta.env.VITE_GE_TAG_ALIAS as string;
export const GE_FEATURE_ENABLE = import.meta.env
  .VITE_GE_FEATURE_ENABLE as string;

export const BH_CAS_BASE_URL: string =
  ENVIRONMENT === "prd"
    ? "https://cdp.sph.com.sg/"
    : "https://uat-cdp.sph.com.sg/";

export const BH_GE_HEADLINE_ID: string =
  ENVIRONMENT === "prd" ? "1271" : "1751";

export const BH_GE_RECORDED_VIDEO: string =
  ENVIRONMENT === "prd" ? "1267" : "1750";

export const BH_GE_ST_WIDGET: string = ENVIRONMENT === "prd" ? "1268" : "1749";

export const BH_GOV_TECH_WIDGET: string =
  ENVIRONMENT === "prd" ? "1269" : "1748";

export const BH_GE_LIVE_BLOG_WIGET: string =
  ENVIRONMENT === "prd" ? "1270" : "1747";

export const BH_MOBILE_BANNERS_UGC: string =
  ENVIRONMENT === "prd" ? "1266" : "1744";
