import { capitalizeFirstCharOnly } from "@helper/stringTransform";
import { isArrayWithElements, isClientSide } from "@helper/utils";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { NoSSR } from "@sphtech/web2-core/components";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import CelebrateResult from "./CelebrateResult";
import CollapseSection from "./CollapseSection";
import { shuffle } from "./helpers/shuffle";
import { usePuzzleData } from "./hooks/usePuzzleData";
import QuestionDetailSection from "./QuestionDetailSection";
import ResultsSection from "./ResultsSection";
import StatusMessage from "./StatusMessage";

export type QuizExpandEvent = {
  quizExpanded: boolean;
  isMobile: boolean;
};
export type QuizOptionSelectedEvent = {
  optionSelected: boolean;
  isMobile: boolean;
};

export type WordPuzzleProps = {
  DefaultQuestions?: { [key: string]: string }[];
};

export default function WordPuzzle(props: WordPuzzleProps): JSX.Element {
  const { DefaultQuestions } = props;
  const currentDate = new Date();
  const puzzleDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
  const cookieExpand = Cookies.get("puzzle_popup_collapsed") === "1";
  const completedDailyQuiz = Cookies.get("completed_daily_quiz") === "1";

  const [options, setOptions] = useState<string[]>([]);
  const [questionResult, setQuestionResult] = useState<string>("");
  const [isExpanded, setIsExpanded] = useState<boolean>(!cookieExpand);
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [englishWord, setEnglishWord] = useState<string>(""); // The question
  const [correctMalayWord, setCorrectMalayWord] = useState<string>("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(true);
  const [incorrectCombination, setIncorrectCombination] = useState<string>("");
  const [incorrectCount, setIncorrectCount] = useState<number>(0); // Added state
  const [questionNum, setQuestionNum] = useState<number>(1);
  const [clkCount, setClkCount] = useState<number>(1);

  const [showNextPre, setShowNextPrev] = useState<boolean>(false);
  const { questions } = usePuzzleData(puzzleDate);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [hasAnswered, setHasAnswered] = useState<boolean>(false); // Added state to track if the question has been answered
  const [checkInteractDataLayer, setCheckInteractDataLayer] =
    useState<boolean>(false);
  const [checkVisibleDataLayer, setCheckVisibleDataLayer] =
    useState<boolean>(false);
  const [showCelebration, setShowCelebration] = useState<boolean>(false);

  const bhCustomEventAnalyticsCall = useBHCustomEventAnalyticsCall();

  function handleWindowSizeChange() {
    if (isClientSide) {
      setIsMobile(window.innerWidth <= 768);
    }
  }

  const handleQuizClose = () => {
    bhCustomEventAnalyticsCall(
      "word puzzle",
      "close quiz",
      window.location.href,
      false,
    );
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isClientSide) return;
    setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const pushVisibleEvent = useCallback(() => {
    if (!checkVisibleDataLayer) {
      setCheckVisibleDataLayer(true);
      bhCustomEventAnalyticsCall(
        "word puzzle",
        "visible",
        window.location.href,
        true,
      );
    }
  }, [bhCustomEventAnalyticsCall, checkVisibleDataLayer]);

  useEffect(() => {
    if (!isClientSide) return;
    const handleVisibilityCheck = () => {
      const element = document.getElementById("word-puzzle");
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          pushVisibleEvent();
        }
      }
    };

    const checkReadyState = () => {
      if (document.readyState === "complete") {
        handleVisibilityCheck();
      } else {
        const timeoutId = setTimeout(checkReadyState, 100);
        return timeoutId;
      }
    };

    // Initial call to check if document is ready
    const timeoutId = checkReadyState();

    // Clean up the timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [pushVisibleEvent]);

  useEffect(() => {
    if (isArrayWithElements(DefaultQuestions) || questions.length) {
      loadQuestion(
        isArrayWithElements(DefaultQuestions)
          ? DefaultQuestions[0]
          : questions[0],
      );
    }
  }, [questions, DefaultQuestions]);

  const removeCookieAtMidnight = useCallback(() => {
    const currentDate = new Date();
    const tomorrow = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
      0,
      1,
      0,
    );
    const timeUntilMidnight = tomorrow.getTime() - currentDate.getTime();
    setTimeout(() => {
      Cookies.set("puzzle_popup_collapsed", "1");
      Cookies.set("completed_daily_quiz", "0");
      Cookies.set("quiz_option_selected", "0");
      removeCookieAtMidnight();
    }, timeUntilMidnight);
  }, []);

  useEffect(() => {
    removeCookieAtMidnight();
  }, [removeCookieAtMidnight]);

  const loadQuestion = (question: { [key: string]: string }) => {
    const malayWords = Object.values(question);
    const questionWordInEnglish = Object.keys(question)[0];
    setEnglishWord(questionWordInEnglish);
    const correctAnswer = question[questionWordInEnglish];
    setCorrectMalayWord(correctAnswer.toLowerCase());
    setOptions(shuffle([...malayWords.slice(1), correctAnswer]));
    setHasAnswered(false); // Reset hasAnswered when loading a new question
    setSelectedWord(null);
  };

  const handleQuestion = useCallback(() => {
    const nextIndex = currentQuestionIndex + 1;
    setClkCount(clkCount + 1);
    if (nextIndex < questions.length) {
      setQuestionNum(questionNum + 1);
      setQuestionResult("correctNext");
      setCurrentQuestionIndex(nextIndex);
      loadQuestion(questions[nextIndex]);
    } else {
      setQuestionResult("");
      setShowNextPrev(true);
      setShowResult(true);
      setResultsVisible(true);
    }
  }, [clkCount, currentQuestionIndex, questionNum, questions]);

  const handleWordClick = (word: string) => {
    setSelectedWord(word);

    const userInteracted = !checkInteractDataLayer;
    // Trigger only once.
    if (userInteracted) {
      // Emit the custom event when word is selected.
      const event = new CustomEvent<QuizOptionSelectedEvent>(
        "quizOptionSelected",
        {
          detail: { optionSelected: true, isMobile: isMobile },
        },
      );
      window.dispatchEvent(event);
      Cookies.set("quiz_option_selected", "1");
    }

    if (!checkInteractDataLayer) {
      setCheckInteractDataLayer(true);
      bhCustomEventAnalyticsCall(
        "word puzzle",
        "interact",
        window.location.href,
        false,
      );
    }
    if (word.toLowerCase() === correctMalayWord.toLowerCase()) {
      if (!resultsVisible || showNextPre) {
        bhCustomEventAnalyticsCall(
          "word puzzle",
          "success",
          window.location.href,
          false,
        );
        setQuestionResult("correct");
        setIncorrectCombination(""); // Clear incorrect combination on correct answer
      }
      setTimeout(() => {
        if (!resultsVisible || showNextPre) setQuestionResult("correctNext");
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex < questions.length) {
          setQuestionNum(questionNum + 1);
          setCurrentQuestionIndex(nextIndex);
          loadQuestion(questions[nextIndex]);
          setSelectedWord(null);
        } else {
          setShowNextPrev(true);
          setShowResult(true);
          setResultsVisible(true);
          setQuestionResult("");
          Cookies.set("completed_daily_quiz", "1");
          if (incorrectCount === 0) {
            setShowCelebration(true);
          }
          setTimeout(() => {
            setIsExpanded(false);
            // Emit the custom event
            const event = new CustomEvent<QuizExpandEvent>(
              "quizExpandedChange",
              {
                detail: { quizExpanded: false, isMobile: isMobile },
              },
            );
            window.dispatchEvent(event);
            Cookies.set("puzzle_popup_collapsed", "1");
            // Redirect to Kuiz page.
            redirectToKuizPage();
          }, 3000);
        }
      }, 1000);
    } else {
      bhCustomEventAnalyticsCall(
        "word puzzle",
        "failed",
        window.location.href,
        false,
      );
      setQuestionResult("incorrect");
      setIncorrectCombination(
        `${capitalizeFirstCharOnly(word)} = ${capitalizeFirstCharOnly(questions[currentQuestionIndex][word])}`,
      );
      if (!hasAnswered) {
        // Check if the question has already been answered incorrectly
        setIncorrectCount((prev) => prev + 1); // Increment incorrect count
        setHasAnswered(true); // Mark the question as answered
      }
      setTimeout(() => {}, 1000);
    }
  };

  const redirectToKuizPage = () => {
    window.location.href = "/kuiz-bh";
  };

  const toggleExpand = () => {
    if (isExpanded) {
      bhCustomEventAnalyticsCall(
        "word puzzle",
        "click -",
        window.location.href,
        false,
      );
    }
    setIsExpanded((prev) => !prev);
    // Emit the custom event
    const event = new CustomEvent<QuizExpandEvent>("quizExpandedChange", {
      detail: { quizExpanded: !isExpanded, isMobile: isMobile },
    });
    window.dispatchEvent(event);
    Cookies.set("puzzle_popup_collapsed", isExpanded ? "1" : "0");
  };

  return (
    <>
      {isOpen && (
        <div id="word-puzzle" className="fixed bottom-0 z-50 w-full left-0">
          <div>
            {!isExpanded && (
              <CollapseSection
                isMobile={isMobile}
                toggleExpand={toggleExpand}
                dailyQuizCompleted={completedDailyQuiz}
              />
            )}
            {isExpanded && (
              <>
                {!showCelebration && (
                  <>
                    <QuestionDetailSection
                      isMobile={isMobile}
                      toggleExpand={toggleExpand}
                      handleQuestion={handleQuestion}
                      clkCount={clkCount}
                      questionNum={questionNum}
                      questions={
                        isArrayWithElements(DefaultQuestions) &&
                        DefaultQuestions.length > 0
                          ? DefaultQuestions
                          : questions
                      }
                      englishWord={englishWord}
                      options={options}
                      selectedWord={selectedWord}
                      correctMalayWord={correctMalayWord}
                      handleWordClick={handleWordClick}
                    />
                    <StatusMessage
                      result={questionResult}
                      incorrectCombination={incorrectCombination}
                    />
                  </>
                )}
                {resultsVisible && !showCelebration && (
                  <ResultsSection
                    isMobile={isMobile}
                    correctCount={questions.length - incorrectCount} // Pass the corrected count
                    totalCount={questions.length}
                    handleQuizClose={handleQuizClose}
                    // isCurrentDate={isCurrentDate}
                    // showNextPre={showNextPre}
                    // handleQuizNavigation={handleQuizNavigation}
                    showResult={showResult}
                  />
                )}
                <NoSSR>{showCelebration && <CelebrateResult />}</NoSSR>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
