/**
 * Company: SPHMedia
 * Description: [Top story for GE]
 */

import ArticleCard from "@src/app/components/Elements/Card/Article/ArticleCard";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { ReactElement } from "react";

type GETopStoryWidgetProps = {
  articles: ProcessedArticleData[];
};

/**
 * TopStoryWidget
 * Description: [Editorial curated GE top stories]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GETopStoryWidget(
  props: GETopStoryWidgetProps,
): ReactElement {
  const { articles } = props;

  const firstArticle = articles.slice(0, 1)[0];
  const otherArticles = articles.slice(1, 3);

  return (
    <div className="flex gap-5 flex-col">
      <div>
        <ArticleCard
          displayWidth={414}
          displayHeight={310}
          elements={{
            hasParaWithTitle: true,
          }}
          overrideStyle={{
            cardWrapper: "flex flex-col md:flex-row gap-[10px] md:gap-5",
            headerWrapper: "md:basis-1/2",
            hero: "mb-0",
            imageStyle: "w-full",
            contentWrapper: "md:basis-1/2 gap-0 mb-0",
            dateTitleWrapper: "flex flex-col-reverse gap-xs",
            dateStyle:
              "text-[14px] leading-[150%] font-semimedium text-black-700",
            titleParaWrapper: "flex flex-col gap-xxs",
            titleStyle:
              "text-[22px] md:text-[22px] text-black-800 font-medium leading-[130%]",
            description: "hidden md:block leading-[150%] text-grey-350",
          }}
          {...firstArticle}
        />
      </div>
      <div className="flex flex-col md:flex-row gap-5">
        {otherArticles.map((article, idx) => (
          <ArticleCard
            key={`ge-top-story-${idx}`}
            displayWidth={113}
            displayHeight={86}
            overrideStyle={{
              cardWrapper: "flex gap-3 md:w-1/2",
              headerWrapper: "w-[113px]",
              hero: "mb-0",
              imageStyle: "w-[113px]",
              contentWrapper: "w-[290px] gap-0 mb-0",
              dateTitleWrapper: "flex flex-col-reverse gap-xs",
              dateStyle:
                "text-[14px] leading-[150%] font-semimedium text-black-700",
              titleStyle:
                "md:text-sm text-black-800 font-medium leading-[120%]",
            }}
            {...article}
          />
        ))}
      </div>
    </div>
  );
}
