/**
 * Company: SPHMedia
 * Description: [GE widet with map]
 */

import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

/**
 * GEMapWidget
 * Description: [GE widget by data.gov.sg (https://dgs-elections.vercel.app/en/embed)]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GEMapWidget({
  geGovTechContent,
  showTitle = true,
  wrapperStyle = "",
}: {
  geGovTechContent: string;
  showTitle?: boolean;
  wrapperStyle?: string;
}): ReactElement {
  const widgetTitle = "Peta Divisyen Kawasan Undi";

  return (
    <div
      className={twMerge(
        "flex flex-col gap-xs p-5 my-5 bg-grey-950",
        wrapperStyle,
      )}
    >
      {showTitle && (
        <div className="text-[22px] font-medium font-secondary leading-[130%] text-center">
          {widgetTitle}
        </div>
      )}
      <div dangerouslySetInnerHTML={{ __html: geGovTechContent }} />
    </div>
  );
}
