import useSetFirstRenderedArticleIds from "@components/Elements/LoadMore/SetFirstRenderedArticleIds";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import ArticleCard, {
  ArticleCardElementProps,
  ArticleCardStyleProps,
} from "@elements/Card/Article/ArticleCard";
import type { CreateLoadMoreHandlerProps } from "@elements/LoadMore/CreateLoadMoreHandler";
import { CreateLoadMoreHandler } from "@elements/LoadMore/CreateLoadMoreHandler";
import LoadMore from "@elements/LoadMore/LoadMore";
import fetchBHApi from "@helper/fetchBHApi";
import { GE_TAG_ALIAS } from "@helper/getEnvVariables";
import { displayAfter } from "@helper/utils";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import React, { useState } from "react";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

type KeywordArticlesListProps = {
  articles: ProcessedArticleData[];
  excludeIds: string;
  adsSectionName: string;
  adsSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

export default function GEKeywordArticlesList({
  articles,
  excludeIds,
  adsSectionName,
  adsSlotTarget,
}: KeywordArticlesListProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = articles.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(articles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? articles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: GE_TAG_ALIAS,
      sort: lastLoadedArticle?.sort,
    };

    const fetchMoreTagsStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const queryParams = new URLSearchParams({
        tagAlias: requestData.filterArticlesBasedOn as string,
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
        size: `${PAGE_SIZE}`,
        excludeIds: excludeIds,
      }).toString();
      const response: ProcessedArticleData[] = await fetchBHApi(
        `more-tags-stories?${queryParams}`,
        "GET",
      );

      return response;
    };

    const createLoadMoreHandlerProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreTagsStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      lastLoadedArticle,
      loadMoreData,
      renderedArticleIds,
      pageSize: PAGE_SIZE,
      pageNumber,
    };

    const response = await CreateLoadMoreHandler(createLoadMoreHandlerProps);
    return response;
  };

  const articleCardElements: ArticleCardElementProps = {
    hasParaWithTitle: true,
  };
  const articleCardOverrideStyle: ArticleCardStyleProps = {
    cardWrapper: "flex flex-row gap-4",
    contentWrapper: "gap-0 mb-0",
    imageStyle: "w-[138px] md:w-[284px]",
    hero: "mb-0",
    dateTitleWrapper: "flex flex-col gap-xs",
    dateStyle: "md:py-0 text-xxs lg:text-xs text-grey-400 font-semibold",
    titleStyle: "font-semimedium",
    titleParaWrapper: "flex flex-col gap-xs",
    description:
      "font-primary text-xs leading-[150%] font-regular mb-0 hidden md:block",
  };

  return (
    <div className="flex flex-col gap-5">
      {[...articles, ...loadMoreData].map((context, index) => (
        <React.Fragment key={index}>
          {displayAfter(index, PAGE_SIZE) && (
            <div data-testid={index}>
              <CompanionAdsLb
                uniqueSectionName={adsSectionName}
                index={index / PAGE_SIZE}
                pageAdTargetType={PageAdTargetingTypeEnum.TAG}
                articleSlotTarget={adsSlotTarget}
              />
            </div>
          )}
          <ArticleCard
            forNewDesign={true}
            displayWidth={284}
            displayHeight={190}
            elements={articleCardElements}
            overrideStyle={articleCardOverrideStyle}
            {...context}
          />
        </React.Fragment>
      ))}
      {showLoadMore ? (
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreStories}
          loadText="Lagi Cerita"
        />
      ) : null}
    </div>
  );
}
