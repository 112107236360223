import CASBlock from "@components/CASBlock/CASBlock";
import SideBlockAds from "@components/Container/SideBlockAds";
import ShareSocial, {
  SocialVariant,
} from "@components/Elements/ShareSocial/ShareSocial";
import { CAS_UGC_SECTION_BANNER_ID } from "@helper/getEnvVariables";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import CampaignStoriesList from "./UGCCampaignStoriesList";
import UGCEmptyListing from "./UGCEmptyListing";
import type { UGCListingPageResponse } from "./UGCListingPage.server";
import UGCListingPageMetaData from "./UGCListingPageMetaData";

export function UGCListingPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<UGCListingPageResponse, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (
    dataLoaderResponse.type === ResponseType.SUCCESS &&
    dataLoaderResponse.payload.isCampignValid
  ) {
    return (
      <UGCEmptyListing
        sectionName="mata-mata"
        urlPath={dataLoaderResponse.payload.urlAlias}
        data={dataLoaderResponse.payload.data}
      />
    );
  }
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const articles = dataLoaderResponse.payload.data;
  const campaignTitle = articles[0]?.campaignReference?.title
    ? articles[0]?.campaignReference.title
    : "";
  const urlPath = dataLoaderResponse.payload.urlAlias;
  const sectionName = "mata-mata";
  const pageUrl = `${sectionName}/${urlPath}`;
  const { gaData, metaTags } = UGCListingPageMetaData({
    sectionName,
    pageUrl,
    campaignTitle,
  });

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    hasLb1Ads: false,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  const socialIconList = ["facebook", "whatsapp"];

  return (
    <BHBasePage {...basePageProps}>
      <div className="max-w-screen-cxl m-auto">
        <CASBlock casId={CAS_UGC_SECTION_BANNER_ID} />
        <div className="text-lg font-primary">
          <div className="mb-sm font-semibold">
            <a
              className="text-blue-300 hover:text-blue-700 hover:underline"
              href={`/${sectionName}`}
            >
              {sectionName}
            </a>
          </div>
          <div className="flex flex-col-reverse md:flex-row md:items-center justify-between lg:justify-start mb-xs">
            <div className="self-start w-full text-blue-100 font-semibold">
              {campaignTitle}
            </div>
            <div className="self-end w-12/12 lg:w-6/12 flex gap-1">
              {socialIconList.map((variant, index) => (
                <ShareSocial
                  variant={variant as SocialVariant}
                  pageTitle={campaignTitle}
                  pagePath={pageUrl}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full gap-4">
          <CampaignStoriesList
            campaignStories={articles}
            urlPath={urlPath}
            sectionName={sectionName}
          />
          <SideBlockAds
            adsSectionName={sectionName}
            adsOnly={true}
            pageAdTargetingProps={pageAdTargetValue}
          />
        </div>
      </div>
    </BHBasePage>
  );
}
