import useOKTAUserStore from "@app/store/useOKTAUserStore";
import SPHLogoMobile from "@assets/BHLogoMobile.svg";
import closeIcon from "@assets/header/menu/menuCloseNew.svg";
import styles from "@components/HeaderNew/Header.module.css";
import {
  MOBILE_MAIN_LIST,
  MOBILE_MISC_LIST,
  MOBILE_SECONDARY_LISTING,
  MOBILE_SECTION_LIST,
  MOBILE_SECTION_LIST_NEW,
} from "@components/HeaderNew/headerMenuConstants";
import { useSubscribeLink } from "@helper/checkMobileAppLink";
import { isClientSide } from "@helper/utils";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import { SHOW_SUB_CATEGORIES } from "@src/app/helper/getEnvVariables";
import cx from "classnames";
import { ReactElement, useEffect } from "react";

import { HeaderMenuList } from "./HeaderMenuList";
import MobileMenuWithSubCategories from "./MobileMenuWithSubCategories";
import MySphLoginButton from "./MySphLoginButton";
import { SearchInput } from "./Search/SearchInput";
import UserInfo from "./UserInfo/UserInfo";

export type MobileMenuModalProps = {
  expandMenu: boolean;
  onClick: () => void;
};

export function MobileMenuModal({
  expandMenu,
  onClick,
}: MobileMenuModalProps): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;

  useEffect(() => {
    if (!isClientSide) return;

    if (expandMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [expandMenu]);
  const subLink = useSubscribeLink();

  return (
    <div
      className={`fixed top-0 left-0 overflow-x-hidden w-full h-full bg-black-800 text-white-500 text-sm font-semimedium z-[999] transform transition-all duration-500 ease-in-out
        ${
          expandMenu
            ? "translate-y-0 opacity-100"
            : "-translate-y-full opacity-0"
        }`}
    >
      <div className="sticky top-0 bg-black-800 z-50">
        <div className="flex items-center justify-between h-[40px] px-xs">
          <div className="lg:hidden">
            <img
              src={SPHLogoMobile}
              alt="SPH Logo mobile"
              width={132}
              height={25}
              className="lg:hidden"
            />
          </div>
          <button onClick={onClick}>
            <img src={closeIcon} alt="menu" width={24} height={24} />
          </button>
        </div>
        <div>
          <SearchInput expanded={true} isMobile forModal />
        </div>
      </div>
      <div>
        {userDisplayName && (
          <div className="flex justify-start items-center">
            <UserInfo
              rootClassName="whitespace-nowrap w-full"
              userInfo={
                {
                  ...userInfo,
                  lastname: userDisplayName,
                  loginId: OKTAUserInfo.loginid,
                  sub: OKTAUserInfo.reguserstatus,
                } as UserInfoType
              }
            />
          </div>
        )}
        <div
          className={cx(
            styles.mobileUserSection,
            "flex items-center h-[54px] gap-xl text-sm border-white-600 border-b-[1px] border-opacity-25 px-sm",
            !userDisplayName && "justify-center",
            userDisplayName && "justify-start",
          )}
        >
          {subLink && (
            <>
              <a href={subLink}>Langgan</a>
              {!userDisplayName && (
                <>
                  <span className="text-white-100 opacity-25">|</span>
                </>
              )}
            </>
          )}
          {!userDisplayName && (
            <MySphLoginButton
              buttonStyle="gap-2"
              iconWidth="20px"
              iconHeight="19px"
              textOnly
            />
          )}
        </div>
        <div className="mt-sm mb-sm px-sm">
          <HeaderMenuList
            testId="mobile-misc-links"
            items={MOBILE_MISC_LIST}
            type="mobileMisc"
          />
          {parseInt(SHOW_SUB_CATEGORIES) === 1 ? (
            <MobileMenuWithSubCategories
              rootClassName="-mx-sm"
              menuItems={MOBILE_SECTION_LIST_NEW}
            />
          ) : (
            <HeaderMenuList
              testId="mobile-main-links"
              items={MOBILE_SECTION_LIST}
              type="mobileSection"
            />
          )}
          <HeaderMenuList
            testId="mobile-main-links"
            items={MOBILE_SECONDARY_LISTING}
            type="mobileSecondary"
          />
          <HeaderMenuList
            testId="mobile-main-links"
            items={MOBILE_MAIN_LIST}
            type="mobileMain"
          />
        </div>
      </div>
    </div>
  );
}
