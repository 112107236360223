import { SectionDetails } from "@components/HeaderNew/headerMenuConstants";
import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { findStyleBySectionAndSubCategory } from "@helper/utils";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

type SubSectionNavListProps = {
  items: SectionDetails[] | undefined;
  displaySection: string;
};

export function SubSectionNavList({
  items,
  displaySection,
}: SubSectionNavListProps) {
  const { pathname } = useLocation();

  const isActive = (location: string): boolean => {
    const pathnameOnly = location.split("?")[0];
    return pathname === pathnameOnly;
  };

  const { selectedColor } =
    findStyleBySectionAndSubCategory(displaySection) || {};
  if (items === undefined) {
    return (
      <div
        className={`border-b-2 lg:border-t-2 lg:border-b-0 border-${selectedColor}`}
      ></div>
    );
  }

  return (
    <List
      testId="sub-section-nav"
      style={`flex items-center gap-[28px] lg:gap-[36px] pb-2 lg:pb-[5px] lg:pt-[12px] 
        overflow-x-scroll lg:overflow-visible 
        border-b-2 lg:border-b-0 lg:border-t-2 border-${items[0].selectedColor}`}
    >
      {items.map((item: SectionDetails, index: number) => (
        <ListItem
          key={index}
          testId="sub-section-nav-item"
          className="cursor-pointer flex items-center justify-center text-sm lg:text-xs"
        >
          <div className={`flex items-center gap-xxs min-w-max text-nowrap`}>
            <Link
              reloadDocument
              key={index}
              to={item.url}
              className={cx(`
                ${
                  isActive(item.url)
                    ? `text-${item.selectedColor} font-semibold`
                    : "text-black-700 font-regular"
                }`)}
              {...(item.newTab && { target: "_blank" })}
            >
              {item.title}
            </Link>
          </div>
        </ListItem>
      ))}
    </List>
  );
}
