import { adSlotSizes } from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

export function AISummaryAdsLbEnglish({
  uniqueSectionName,
  index,
  gsChannels,
  articleSlotTarget,
  rootClassName,
  wrapperStyle,
  applySizeMapping = true,
}: {
  uniqueSectionName?: string;
  index: number;
  wrapperStyle?: string;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  overRideSlot?: boolean;
  rootClassName?: string;
  applySizeMapping?: boolean;
}) {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        wrapperStyle,
      )}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB2,
          slot: GoogleAdsSlotFactory.lb2(uniqueSectionName),
          id:
            index === 0
              ? "dfp-ad-lb2-summary-en"
              : `dfp-ad-imu1-summary-en-${index}`,
          rootClassName: rootClassName ? rootClassName : "m-0",
          pageNumber: index.toString(),
          gsChannels,
          articleSlotTarget,
          adSlotSize: adSlotSizes["lb2"],
          applySizeMapping,
        }}
      />
    </div>
  );
}

export function AISummaryAdsLbMalay({
  uniqueSectionName,
  index,
  gsChannels,
  articleSlotTarget,
  rootClassName,
  wrapperStyle,
  applySizeMapping = true,
}: {
  uniqueSectionName?: string;
  index: number;
  wrapperStyle?: string;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  overRideSlot?: boolean;
  rootClassName?: string;
  applySizeMapping?: boolean;
}) {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        wrapperStyle,
      )}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB2,
          slot: GoogleAdsSlotFactory.lb2(uniqueSectionName),
          id:
            index === 0
              ? "dfp-ad-lb2-summary-ms"
              : `dfp-ad-imu1-summary-ms-${index}`,
          rootClassName: rootClassName ? rootClassName : "m-0",
          pageNumber: index.toString(),
          gsChannels,
          articleSlotTarget,
          adSlotSize: adSlotSizes["lb2"],
          applySizeMapping,
        }}
      />
    </div>
  );
}
