import ArticleCard from "@components/Elements/Card/Article/ArticleCard";
import LoadMore from "@components/Elements/LoadMore/LoadMore";
import VerticalStoryPackage from "@components/HomePageStoryPackages/VerticalStoryPackage";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import { AdsLb2 } from "@elements/Advertisement/variant/AdsLb";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import type { CreateLoadMoreHandlerProps } from "@elements/LoadMore/CreateLoadMoreHandler";
import { CreateLoadMoreHandler } from "@elements/LoadMore/CreateLoadMoreHandler";
import useSetFirstRenderedArticleIds from "@elements/LoadMore/SetFirstRenderedArticleIds";
import fetchBHApi from "@helper/fetchBHApi";
import { displayAfter } from "@helper/utils";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import type {
  FetchMoreArticlesFunction,
  MoreArticlesRequestData,
} from "@typings/MoreArticlesApi";
import { useState } from "react";

const PAGE_SIZE = 8;
const INITIAL_ARTICLE_COUNT = 12;

type SectionArticlesListNewProps = {
  sectionArticles: ProcessedArticleData[];
  sectionName: string | undefined;
  adsSectionName: string | undefined;
  paywall: LabelCardIconVariety | null;
};

export default function SectionArticlesListNew({
  sectionArticles,
  sectionName,
  adsSectionName,
  paywall,
}: SectionArticlesListNewProps) {
  const headerArticle = [...sectionArticles.slice(0, 4)];
  const onlineStories = [...sectionArticles.slice(4)];
  const contentAccess = headerArticle[0].contentAccess;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = sectionArticles.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(sectionArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? sectionArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: paywall !== null ? contentAccess : sectionName,
      sort: lastLoadedArticle && lastLoadedArticle.sort,
    };

    const fetchMorePaywallStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[]> => {
      const queryParams = new URLSearchParams({
        contentAccess: requestData.filterArticlesBasedOn as string,
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
        size: "8",
      }).toString();
      const response: ProcessedArticleData[] = await fetchBHApi(
        `more-section-paywall-stories?${queryParams}`,
        "GET",
      );
      return response;
    };

    const fetchMoreStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      // Construct the query string
      const queryParams = new URLSearchParams({
        section: requestData.filterArticlesBasedOn as string,
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
        size: "8",
      }).toString();
      const response: ProcessedArticleData[] = await fetchBHApi(
        `more-section-stories?${queryParams}`,
        "GET",
      );
      return response;
    };

    const fetchFunction: FetchMoreArticlesFunction =
      paywall !== null ? fetchMorePaywallStoriesData : fetchMoreStoriesData;
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  return (
    <>
      <div className="w-full">
        <WidgetWrapper>
          <VerticalStoryPackage
            renderItems={headerArticle}
            styles={{
              wrapperStyle: "border-t-0",
            }}
            firstArticleProps={{
              hasParagraph: true,
              hasSectionName: paywall !== null ? true : false,
              hasNewIcon: paywall !== null ? false : true,
            }}
            restArticleProps={{
              hasParagraph: false,
              hasSectionName: paywall !== null ? true : false,
              hasNewIcon: paywall !== null ? false : true,
            }}
          />
        </WidgetWrapper>
        <div className="w-full bg-white-200">
          <AdsLb2
            uniqueSectionName={adsSectionName}
            pageAdTargetType={PageAdTargetingTypeEnum.LISTING}
            hasStripeBg={false}
          />
        </div>
        <div className="w-full">
          {[...onlineStories, ...loadMoreData].reduce(
            (acc: JSX.Element[], _, index) => {
              // Insert an ad every PAGE_SIZE articles, outside the wrapper
              if (displayAfter(index, PAGE_SIZE)) {
                acc.push(
                  <div key={`ad-${index}`} className="w-full bg-white-200">
                    <CompanionAdsLb
                      uniqueSectionName={adsSectionName}
                      index={index / PAGE_SIZE}
                      pageAdTargetType={PageAdTargetingTypeEnum.LISTING}
                      stripeBackground={false}
                      overRideSlot={false}
                      applySizeMapping={true}
                    />
                  </div>,
                );
              }
              // Start a new batch every 8 articles
              if (index % PAGE_SIZE === 0) {
                acc.push(
                  <WidgetWrapper key={`batch-${index}`} className="my-[20px]">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                      {[...onlineStories, ...loadMoreData]
                        .slice(index, index + PAGE_SIZE)
                        .map((item, idx) => (
                          <ArticleCard
                            key={`batch-${index}-${idx}`}
                            forNewDesign={true}
                            displayWidth={160}
                            displayHeight={120}
                            elements={{
                              hasSectionName: paywall !== null ? true : false,
                              hasNewIcon: paywall !== null ? false : true,
                              hasParagraph: false,
                            }}
                            overrideStyle={{
                              cardWrapper: "flex justify-center w-full gap-xs",
                              hero: "mb-xs",
                              headerWrapper:
                                "basis-3/10 md:basis-2/5 lg:basis-3/10 block w-full",
                              contentWrapper: `basis-7/10 md:basis-3/5 lg:basis-7/10 w-full mb-0 ${paywall !== null ? "gap-xs" : "gap-1"}`,
                              dateStyle:
                                "text-black-700 font-semimedium leading-[21px]",
                              dateTitleWrapper: "flex flex-col-reverse gap-xs",
                              titleStyle:
                                "lg:text-sm font-medium font-secondary text-black-800 leading-[19.2px]",
                              titleHoverStyle: "hover:text-blue-400",
                              sectionNameStyle:
                                "text-[12px] lg:text-[12px] font-semibold",
                              sectionNameWrapper: "justify-between",
                            }}
                            {...item}
                          />
                        ))}
                    </div>
                  </WidgetWrapper>,
                );
              }

              return acc;
            },
            [],
          )}

          {showLoadMore ? (
            <LoadMore
              rootClassName="my-4"
              onLoadMore={handleLoadMore}
              hasMore={hasMoreStories}
              loadText="Lagi Cerita"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
