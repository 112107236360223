import SideBlockAds from "@container/SideBlockAds";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import type { AuthorPayloadResponseType } from "./Author.server";
import AuthorMetaData from "./AuthorMetaData";
import AuthorStoriesList from "./AuthorStoriesList";

export function AuthorPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<AuthorPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const articles = dataLoaderResponse.payload.data;
  const author = articles[0].authors && articles[0].authors[0];
  const authorAlias = dataLoaderResponse.payload.authorAlias;
  const sectionName = "author";
  const { gaData, metaTags } = AuthorMetaData(authorAlias);

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="max-w-screen-cxl m-auto flex flex-col lg:flex-row w-full gap-4">
        <AuthorStoriesList
          articles={articles}
          author={author}
          authorAlias={authorAlias}
          sectionName={sectionName}
        />
        <SideBlockAds
          adsSectionName={sectionName}
          adsOnly={true}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
    </BHBasePage>
  );
}
