import { PositionError } from "@typings/foodMapTypes";

export default function FoodMapLocationPermission({
  error,
}: {
  error: GeolocationPositionError | PositionError;
}) {
  let message: string;
  if (error.code == 1) {
    message =
      "To allow the Halal Food Map to recommend halal eateries near you, please enable the Location Service under 'Settings' > 'Privacy' > 'Location Services' & reload this page.";
  } else if (error.code == 2) {
    message =
      'Location Service disabled. Please enable the Location Service and tap on "Allow" on prompt.';
  } else if (error.code == 404) {
    message =
      "You are accessing the foodmap outside Singapore. Click on the BH Icon to go back to home page";
  } else {
    message = "Please try again after some time.";
  }
  return (
    <div className="flex flex-col px-xl py-md text-center text-md text-black-300">
      {message}
    </div>
  );
}
