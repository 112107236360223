import SummariseButton from "@assets/article/summariseButton.svg";
import SummariseButtonHover from "@assets/article/summariseButtonHover.svg";
import {
  getAt,
  getVisitorcat,
} from "@components/Translation/helper/GetValuesFromGAData";
import { isClientSide } from "@helper/utils";
import {
  CD_LANGUAGE,
  useBHCustomEventAnalyticsCall,
} from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { useBHVirtualPVAnalyticsCall } from "@hooks/useAnalyticsCall/useBHVirtualPVAnalyticsCall";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { AdTargetingType, ArticleAdTargetKeys } from "@typings/Ads.d";
import { useEffect, useState } from "react";

import AISummaryModal from "./AISummaryModal";

export type AISummaryCTAProps = {
  article: ProcessedArticleData;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  pageNumber: number;
};

export default function AISummaryCTA({
  article,
  gsChannels,
  articleSlotTarget,
  pageNumber,
}: AISummaryCTAProps) {
  const { id, adsUniqueSectionName = "", aiSummary, title } = article;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const bhCustomEvent = useBHCustomEventAnalyticsCall();
  const bhVirtualPV = useBHVirtualPVAnalyticsCall();
  const summarisedQueryParam = "summarised";

  useEffect(() => {
    if (!isClientSide) return;

    if (isModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [isModalOpen]);

  const handleCTAClick = () => {
    bhCustomEvent(
      "ai summary",
      "click",
      window.location.href,
      undefined,
      undefined,
      CD_LANGUAGE.MALAY,
    );
    const at = getAt();
    const visitorcat = getVisitorcat();
    const eventTitle = `Summary by AI - ${title}`;
    bhVirtualPV(eventTitle, CD_LANGUAGE.MALAY, at, visitorcat);

    const url = new URL(window.location.href);
    if (!url.searchParams.has(summarisedQueryParam)) {
      // Add the summarised query parameter
      url.searchParams.append(summarisedQueryParam, "1");
    }

    window.history.pushState(
      { modal: "AI summary" },
      document.title,
      url.toString(),
    );

    setIsModalOpen(true);
    setTimeout(() => {
      setIsAnimating(true);
    }, 50);
  };

  useEffect(() => {
    if (!isClientSide) return;

    const handlePopState = () => {
      if (isModalOpen) {
        handleModalClose();
      }
    };

    // Add listener for popstate
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isModalOpen]);

  const handleModalClose = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 300);

    // Remove the "?summarised" query parameter
    const url = new URL(window.location.href);
    url.searchParams.delete(summarisedQueryParam);
    window.history.replaceState({}, document.title, url.toString());
  };

  if (!aiSummary) return <></>;

  const { english, malay } = aiSummary;

  if (
    (english?.length == 1 && english[0] == "") ||
    (malay?.length == 1 && malay[0] == "")
  )
    return <></>;

  return (
    <div className="relative">
      {isModalOpen && (
        <div
          data-testid={`aisummary-modal-${id}`}
          className="fixed inset-0 overflow-y-auto w-full h-full bg-white-100 z-[52]"
        >
          <div
            className={`h-full transform transition-all duration-1000 ease-out
          ${
            isAnimating
              ? "translate-y-0 opacity-100"
              : "translate-y-full opacity-0"
          }
        `}
          >
            <AISummaryModal
              id={id}
              adsSectionName={adsUniqueSectionName}
              english={english}
              malay={malay}
              isOpen={isModalOpen}
              onClose={handleModalClose}
              gsChannels={gsChannels}
              articleSlotTarget={articleSlotTarget}
              pageNumber={pageNumber}
            />
          </div>
        </div>
      )}
      {!isModalOpen && (
        <div data-testid={`aisummary-cta-${id}`} className="w-[96px] h-[34px]">
          <button
            onClick={handleCTAClick}
            aria-label="Translate Article"
            style={{
              filter: "drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.25))",
            }}
            className="group"
          >
            <img
              src={SummariseButton}
              alt="AI summary Call to Action"
              width={96}
              height={34}
              className="group-hover:hidden"
            />
            <img
              src={SummariseButtonHover}
              alt="AI summary Call to Action hover"
              width={96}
              height={34}
              className="hidden group-hover:block"
            />
          </button>
        </div>
      )}
    </div>
  );
}
