import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@app/types/Ads.d";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import {
  adSlotSizes,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { twMerge } from "tailwind-merge";

type AdsBottomSpecialProps = {
  uniqueSectionName?: string;
  rootClassName?: string;
  pageAdTargetType?: PageAdTargetValue;
  index: number;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 */

export function CompanionAdsBottomSpecial({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  index,
  gsChannels,
  articleSlotTarget,
}: AdsBottomSpecialProps) {
  const PubPageAdTarget =
    pageAdTargetType &&
    (pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
      string,
      PageAdTargetValue
    >);
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.BOTTOMSPECIAL,
        slot: GoogleAdsSlotFactory.bottomspecial(uniqueSectionName),
        id: `dfp-ad-bottomspecial-${index}`,
        adSlotSize: adSlotSizes["bottomspecial"],
        ...(PubPageAdTarget && { pubAdTargeting: [PubPageAdTarget] }),
        pageNumber: index.toString(),
        gsChannels,
        articleSlotTarget,
      }}
    />
  );
}
