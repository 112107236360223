import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function GeneralElectionsMetaData(
  section: string,
): PageMetaDataValues {
  const metaTags: MetaTagsProps = {
    title:
      "Beritaharian Pilihan Raya Umum Singapura 2025 - Berita Terkini, Singapura, Ekoniaga, Dunia & Sukan",
    description:
      "Berita Harian Pilihan Raya Umum Singapura 2025 - Dapatkan berita terkini, laporan eksklusif, analisis mendalam dan kandungan berbilang media mengenai Singapura, Asia dan dunia.",
    slug: section,
  };

  const gaData: GADataProps = {
    level2: section,
    title: "Pilihan_Raya_Umum_Singapura_2025_Index",
    section: section,
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
