/**
 * Company: SPHMedia
 * Description: This is the base page component for all BH pages
 */

import CASBlock from "@components/CASBlock/CASBlock";
import { MainContainer } from "@components/Container/MainContainer";
import { MainContainerNew } from "@components/Container/MainContainerNew";
import CatfishAd from "@components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import PrestitialAd from "@components/Elements/Advertisement/PrestitialAd";
import { Footer } from "@components/Footer/Footer";
import { FooterNew } from "@components/FooterNew/FooterNew";
import { Header } from "@components/Header/Header";
import { HeaderNew } from "@components/HeaderNew/HeaderNew";
import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import { AdsLb1 } from "@elements/Advertisement/variant/AdsLb";
import type { GADataProps } from "@elements/GAData/GAData";
import GAData from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import MetaTags from "@elements/MetaTags/MetaTags";
import { CAS_EI_MODEL_ID } from "@helper/getEnvVariables";
import { SHOW_NEW_MASTHEAD } from "@helper/getEnvVariables";
import { useNeuronPageViewPush } from "@hooks/useAnalyticsCall/useNeuronPageViewPush";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import useOKTAUserInfo from "@hooks/useOktaUserInfo";
import useScript from "@hooks/useScript/useScript";
import useSendPermutiveIdentify from "@hooks/useSendPermutiveIdentify";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

export type BHBasePageProps = {
  uniqueSectionName?: string;
  adsSectionName?: string;
  children?: React.ReactNode;
  hasLb1Ads?: boolean;
  gaData?: GADataProps;
  metaTags?: MetaTagsProps;
  pageAdTargetValue: PageAdTargetValue;
  hideScrollableSectionMenu?: boolean;
  adsSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  bodyWrapperStyle?: string;
  showGEBanner?: boolean;
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function BHBasePage({
  uniqueSectionName,
  adsSectionName = uniqueSectionName,
  children,
  hasLb1Ads = true,
  gaData,
  metaTags,
  pageAdTargetValue,
  hideScrollableSectionMenu = false,
  adsSlotTarget,
  bodyWrapperStyle = "",
  showGEBanner = true,
}: BHBasePageProps): ReactElement {
  const {
    isPrestitialEnabled,
    isCatfishEnabled,
    topOverlayImpressions,
    topOverlayValidity,
  } = useGlobalAdSetting();
  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });
  useNeuronPageViewPush();
  useSendPermutiveIdentify();

  const pageAdTarget = pubPageAdTargeting[pageAdTargetValue] as AdTargetingType<
    string,
    PageAdTargetValue
  >;

  const renderPage = () => {
    if (parseInt(SHOW_NEW_MASTHEAD)) {
      return (
        <>
          <MainContainerNew>
            <div className="flex justify-center w-full bg-white-200 border-b-[1px] border-black-900">
              {hasLb1Ads && (
                <AdsLb1
                  uniqueSectionName={adsSectionName}
                  pageAdTargetType={pageAdTargetValue}
                  hasStripeBg={false}
                  wrapperStyle="mb-0"
                  articleSlotTarget={adsSlotTarget}
                />
              )}
            </div>
            <HeaderNew
              isHomePage={uniqueSectionName === "home" ? true : false}
              hideScrollableSectionMenu={hideScrollableSectionMenu}
              showGEBanner={showGEBanner}
            />
            <div className={twMerge("my-3 lg:my-md w-full", bodyWrapperStyle)}>
              <PrestitialAd
                slot={GoogleAdsSlotFactory.prestitial(adsSectionName)}
                isPrestitialEnabled={isPrestitialEnabled}
                pubAdTargeting={[pageAdTarget]}
                topOverlayImpressions={topOverlayImpressions}
                topOverlayValidity={topOverlayValidity}
                articleSlotTarget={adsSlotTarget}
              />
              {children}
            </div>
          </MainContainerNew>
          <FooterNew />
        </>
      );
    }

    return (
      <>
        <MainContainer>
          <Header />
          <div className="w-full px-sm">
            {hasLb1Ads && (
              <AdsLb1
                uniqueSectionName={adsSectionName}
                pageAdTargetType={pageAdTargetValue}
                articleSlotTarget={adsSlotTarget}
              />
            )}
            <PrestitialAd
              slot={GoogleAdsSlotFactory.prestitial(adsSectionName)}
              isPrestitialEnabled={isPrestitialEnabled}
              pubAdTargeting={[pageAdTarget]}
              topOverlayImpressions={topOverlayImpressions}
              topOverlayValidity={topOverlayValidity}
              articleSlotTarget={adsSlotTarget}
            />
            {children}
          </div>
        </MainContainer>
        <Footer />
      </>
    );
  };

  return (
    <>
      {gaData && <GAData {...gaData} />}
      {metaTags && <MetaTags {...metaTags} />}
      {renderPage()}
      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(adsSectionName)}
        isCatfishEnabled={isCatfishEnabled}
        pubAdTargeting={[pageAdTarget]}
        articleSlotTarget={adsSlotTarget}
      />
      <CASBlock casId={CAS_EI_MODEL_ID} />
    </>
  );
}
