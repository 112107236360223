import GEIconEnd from "@assets/ge2025/ge-title-home-icon.svg";
import GEIconEndHover from "@assets/ge2025/ge-title-home-icon-hover.svg";
import GEIcon from "@assets/ge2025/ge-title-icon.svg";
import Heading from "@elements/Typography/Heading";
import Link from "@elements/Typography/Link";
import { GE_FEATURE_ENABLE } from "@helper/getEnvVariables";
import GELiveBlog from "@pages/GeneralElections/Components/GELiveBlog";
import GEMapWidget from "@pages/GeneralElections/Components/GEMapWidget";
import GESTWidget from "@pages/GeneralElections/Components/GESTWidget";
import GETopStoryWidget from "@pages/GeneralElections/Components/GETopStoryWidget";
import { ProcessedArticleData } from "@transformer/useOSResponse";

import GELandingPageButton from "./GELandingPageButton";

export type GEWidgetProps = {
  geHeadline?: string;
  geSTWidget?: string;
  geRecordedVideo?: string;
  geTopStories?: ProcessedArticleData[];
  geGovTechWidget?: string;
  geLiveBlog?: string;
};

export function GEWidget({
  geHeadline,
  geSTWidget,
  geRecordedVideo,
  geTopStories,
  geGovTechWidget,
  geLiveBlog,
}: GEWidgetProps) {
  const landingPagePath = "ge2025-pilihan-raya-umum?ref=home";
  if (!parseInt(GE_FEATURE_ENABLE)) {
    return <></>;
  }
  return (
    <div className="flex flex-col">
      <div className="h-[10px] bg-gradient-to-r from-blue-400 to-red-400 -mx-[10px] lg:hidden"></div>
      <div className="border-b-[1px] border-grey-400 py-[10px] md:py-[20px] mb-[10px] md:mb-[20px]">
        <Link className="gap-4 flex items-center" destination={landingPagePath}>
          <img src={GEIcon} alt="ge-icon" width={30} height={30} />
          <div className="flex gap-1 items-center group">
            <Heading
              className="text-red-100 hover:text-red-800 text-[18px] md:text-[23px] 
              font-medium md:font-semibold font-primary leading-[100%] md:leading-[135%]"
            >
              Pilihan Raya Umum Singapura 2025
            </Heading>
            <div className="w-[20px] h-[20px]">
              <img
                src={GEIconEnd}
                alt="ge-icon"
                width={20}
                height={20}
                className="group-hover:hidden"
              />
              <img
                src={GEIconEndHover}
                alt="ge-icon-hover"
                width={20}
                height={20}
                className="hidden group-hover:block"
                style={{
                  height: "20px",
                }}
              />
            </div>
          </div>
        </Link>
        {geHeadline && <div dangerouslySetInnerHTML={{ __html: geHeadline }} />}
      </div>
      <div className="flex gap-[30px]">
        <div className="w-full lg:w-[992px] xl:w-[778px] flex flex-col gap-5">
          {geSTWidget && <GESTWidget geSTWidgetContent={geSTWidget} />}
          {geRecordedVideo && (
            <div dangerouslySetInnerHTML={{ __html: geRecordedVideo }} />
          )}
          {geTopStories && <GETopStoryWidget articles={geTopStories} />}
          {geLiveBlog && (
            <div className="xl:hidden">
              <GELiveBlog variant="body" geLiveBlogContent={geLiveBlog} />
            </div>
          )}
          {geGovTechWidget && (
            <GEMapWidget
              geGovTechContent={geGovTechWidget}
              showTitle={false}
              wrapperStyle="p-0 m-0"
            />
          )}
        </div>
        <div className="hidden xl:block">
          {geLiveBlog && (
            <GELiveBlog
              variant="sidebar"
              geLiveBlogContent={geLiveBlog}
              styles={{ wrapperStyle: "w-[379px] h-full" }}
            />
          )}
        </div>
      </div>
      <div className="px-[10px] self-center xl:self-end mt-5 mb-7">
        <GELandingPageButton GEPagePath={landingPagePath} />
      </div>
    </div>
  );
}
