import { RawImageField } from "@app/types/Opensearch";
import LabelCardIconNew from "@components/HomePageStoryPackages/Label/LabelCardIconNew";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import LabelCardIcon from "@components/Icon/LabelCardIcon";
import { Image } from "@elements/ResponsiveImage/Image";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { HeadingElements } from "@elements/Typography/Heading";
import { RenderTags } from "@elements/Typography/Tags";
import getFallbackSectionImage from "@helper/getFallbackSectionImage";
import {
  findStyleBySection,
  findStyleBySectionAndSubCategory,
  findStyleBySectionNew,
} from "@helper/utils";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type ArticleCardStyleProps = {
  cardWrapper?: string;
  contentWrapper?: string;
  headerWrapper?: string;
  hero?: string;
  description?: string;
  dateStyle?: string;
  titleStyle?: string;
  titleHoverStyle?: string;
  imageStyle?: string;
  tagWrapper?: string;
  sectionNameWrapper?: string;
  sectionNameItem?: string;
  iconStyle?: string;
  sectionNameStyle?: string;
  dateTitleWrapper?: string;
  titleParaWrapper?: string;
};

export type ArticleCardHTMLProps = {
  headingElement: HeadingElements;
};

export type ArticleCardElementProps = {
  hasHeader?: boolean;
  hasIcon?: boolean;
  hasNewIcon?: boolean;
  hasImage?: boolean;
  hasParagraph?: boolean;
  hasSectionName?: boolean;
  hasTitle?: boolean;
  isBreakingNews?: boolean;
  dateVariant?: "dash" | "or";
  hasTags?: boolean;
  hasDateSideBySide?: boolean;
  hasParaWithTitle?: boolean;
};

export type ArticleCardProps = {
  linkTo: string;
  title: string;
  paragraph?: string;
  date: string;
  time: string;
  displayWidth: number;
  displayHeight: number;
  imageField?: RawImageField;
  imageAlt: string;
  sectionName?: string;
  uniqueSectionName?: string;
  parentSection?: string;
  linkToSection?: string;
  contentAccess?: LabelCardIconVariety;
  elements?: ArticleCardElementProps;
  overrideStyle?: ArticleCardStyleProps;
  htmlElements?: ArticleCardHTMLProps;
  urlPath?: string;
  tags?: {
    alias?: string;
    name?: string;
  }[];
  testId?: string;
  forNewDesign?: boolean;
};

export default function ArticleCard({
  title,
  date,
  time,
  paragraph,
  displayWidth,
  displayHeight,
  imageField,
  imageAlt,
  linkTo,
  sectionName,
  uniqueSectionName,
  parentSection,
  linkToSection = "",
  contentAccess,
  htmlElements,
  elements,
  overrideStyle,
  urlPath,
  tags,
  testId = "article-card",
  forNewDesign,
}: ArticleCardProps) {
  const {
    cardWrapper,
    contentWrapper,
    headerWrapper,
    hero,
    description,
    dateStyle,
    titleStyle,
    titleHoverStyle,
    imageStyle,
    tagWrapper,
    sectionNameWrapper,
    sectionNameItem,
    iconStyle,
    sectionNameStyle,
    dateTitleWrapper,
    titleParaWrapper,
  } = overrideStyle || {};

  const {
    hasHeader,
    hasIcon,
    hasNewIcon,
    hasParagraph,
    isBreakingNews,
    hasSectionName,
    dateVariant,
    hasTags,
    hasDateSideBySide,
    hasTitle = true,
    hasImage = true,
    hasParaWithTitle = false,
  } = elements || {};

  const { headingElement } = htmlElements || {};
  const { selectedColor } = forNewDesign
    ? parentSection
      ? findStyleBySectionAndSubCategory(parentSection) || {}
      : findStyleBySectionNew(uniqueSectionName) || {}
    : findStyleBySection(uniqueSectionName) || {};
  const dummyImage = getFallbackSectionImage(sectionName);
  const textColor = selectedColor ? `text-${selectedColor}` : "";

  return (
    <div
      data-testid={testId}
      className={cardWrapper ?? "flex md:flex-col flex-row gap-sm md:gap-0"}
    >
      <div
        className={twMerge(
          "flex md:flex-col flex-row gap-sm md:gap-0",
          headerWrapper,
        )}
      >
        {isBreakingNews && (
          <Link to={linkToSection}>
            <span className={twMerge(`capitalize hover:underline`, textColor)}>
              {sectionName}
            </span>
          </Link>
        )}
        {hasImage && (
          <Link
            to={urlPath ? urlPath : linkTo}
            reloadDocument
            data-testid="link"
            className={twMerge(
              "flex-shrink-0 md:flex-grow-0 md:w-full w-[120px]",
              imageStyle,
            )}
          >
            {hasHeader && (
              <Heading
                data-testid="title-header"
                className={`text-[30px] font-bold font-secondary text-black-200 leading-lg mb-xs hover:cursor-pointer 
                  ${titleHoverStyle ? titleHoverStyle : "hover:underline"}`}
              >
                {title}
              </Heading>
            )}
            <Image
              displayWidth={displayWidth}
              srcWidth={displayWidth}
              srcHeight={displayHeight}
              src={imageField?.url ?? dummyImage}
              alt={imageAlt}
              dataTestId="hero"
              className={hero ? hero : "mb-sm"}
            />
          </Link>
        )}
      </div>

      <div
        className={twMerge(
          "flex flex-col gap-1 mb-1 flex-grow",
          contentWrapper,
        )}
      >
        <div className={twMerge("flex items-center", sectionNameWrapper)}>
          {hasSectionName && (
            <div
              className={twMerge(
                "flex items-center lg:justify-between flex-wrap",
                hasDateSideBySide && "w-full",
                sectionNameItem,
              )}
            >
              <span
                className={twMerge(
                  `mr-2 lg:mr-4 text-xxs lg:text-sm capitalize hover:underline text-nowrap ${textColor}`,
                  sectionNameStyle,
                )}
              >
                <Link to={linkToSection}>{sectionName}</Link>
              </span>
              {hasDateSideBySide && (
                <Date
                  date={date}
                  time={time}
                  variant={dateVariant}
                  className={dateStyle}
                />
              )}
            </div>
          )}
          {hasIcon && (
            <LabelCardIcon
              className={iconStyle}
              contentAccess={contentAccess}
            />
          )}
          {hasNewIcon && (
            <LabelCardIconNew
              className={iconStyle}
              contentAccess={contentAccess}
            />
          )}
        </div>
        <div className={dateTitleWrapper}>
          {!hasDateSideBySide && (
            <Date
              date={date}
              time={time}
              variant={dateVariant}
              className={dateStyle}
            />
          )}
          {hasTitle && (
            <div className={titleParaWrapper}>
              <Link to={urlPath ? urlPath : linkTo} reloadDocument>
                <Heading
                  Element={headingElement}
                  data-testid="title-story"
                  className={twMerge(
                    "font-secondary hover:cursor-pointer text-sm md:text-md text-black-200 font-bold leading-[23px]",
                    titleStyle,
                    titleHoverStyle ? titleHoverStyle : "hover:underline",
                  )}
                >
                  {title}
                </Heading>
              </Link>
              {hasParaWithTitle && (
                <span
                  data-testid="paragraph"
                  className={twMerge(
                    "font-primary text-xxs md:text-xs leading-[21px] font-semimedium",
                    description,
                  )}
                >
                  {paragraph}
                </span>
              )}
            </div>
          )}
        </div>
        {hasParagraph && (
          <span
            data-testid="paragraph"
            className={
              (twMerge(
                "font-primary text-xxs md:text-xs leading-[150%] font-regular mb-sm overflow-hidden",
              ),
              description)
            }
          >
            {paragraph}
          </span>
        )}
        {hasTags && <RenderTags tags={tags} tagsWrapper={tagWrapper} />}
      </div>
    </div>
  );
}
