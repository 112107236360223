import AISummaryCTA from "@components/AISummary/AISummaryCTA";
import TranslationCTA from "@components/Translation/TranslationCTA";
import {
  QuizExpandEvent,
  QuizOptionSelectedEvent,
} from "@components/WordPuzzle/WordPuzzle";
import {
  ARTICLE_SUMMARY_CTA,
  ARTICLE_TRANSLATE_CTA,
} from "@helper/getEnvVariables";
import { isClientSide } from "@helper/utils";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { AdTargetingType, ArticleAdTargetKeys } from "@typings/Ads.d";
import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";

export type DraggableCTAProps = {
  pageNumber: number;
  translatedArticle: ProcessedArticleData;
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  gsChannels?: string[];
  isInfiniteScrollArticle?: boolean;
  visibleArticleId?: string;
};

type Position = {
  x: number;
  y: number;
};

export default function DraggableCTA({
  articleSlotTarget,
  gsChannels,
  pageNumber,
  translatedArticle,
  isInfiniteScrollArticle,
  visibleArticleId,
}: DraggableCTAProps) {
  const { id } = translatedArticle;
  const [position, setPosition] = useState<Position | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState<Position>({ x: 0, y: 0 });
  const isCollapsed = Cookies.get("puzzle_popup_collapsed") === "1";
  const isQuizOptionSelectedAlready =
    Cookies.get("quiz_option_selected") === "1";
  const [isQuizExpanded, setIsQuizExpanded] = useState(!isCollapsed);
  const [isQuizOptionSelected, setIsQuizOptionSelected] = useState(
    isQuizOptionSelectedAlready,
  );
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState(
    isInfiniteScrollArticle || false,
  );
  const [animationClass, setAnimationClass] = useState<string>();

  const buttonRef = useRef<HTMLDivElement>(null);

  const constrainPosition = (x: number, y: number): Position => {
    if (!buttonRef.current) return { x, y };

    const rect = buttonRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    return {
      x: Math.min(Math.max(x, 0), viewportWidth - rect.width),
      y: Math.min(Math.max(y, 0), viewportHeight - rect.height),
    };
  };

  const handleMouseDown = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    if (!buttonRef.current) return;

    const rect = buttonRef.current.getBoundingClientRect();
    const clientX = e.type.includes("mouse")
      ? (e as React.MouseEvent<HTMLButtonElement>).clientX
      : (e as React.TouchEvent<HTMLButtonElement>).touches[0].clientX;
    const clientY = e.type.includes("mouse")
      ? (e as React.MouseEvent<HTMLButtonElement>).clientY
      : (e as React.TouchEvent<HTMLButtonElement>).touches[0].clientY;

    setDragOffset({
      x: clientX - rect.left,
      y: clientY - rect.top,
    });

    if (!position) {
      setPosition(constrainPosition(rect.left, rect.top));
    }

    setIsDragging(true);
  };

  useEffect(() => {
    if (!isClientSide) return;
    setIsMobile(window.innerWidth <= 768);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!isDragging) return;

      const clientX = e.type.includes("mouse")
        ? (e as MouseEvent).clientX
        : (e as TouchEvent).touches[0].clientX;
      const clientY = e.type.includes("mouse")
        ? (e as MouseEvent).clientY
        : (e as TouchEvent).touches[0].clientY;

      const newPosition = constrainPosition(
        clientX - dragOffset.x,
        clientY - dragOffset.y,
      );

      setPosition(newPosition);
    },
    [isDragging, dragOffset],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      // Mouse events
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      // Touch events
      document.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("touchend", handleMouseUp);
      document.addEventListener("touchcancel", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
      document.removeEventListener("touchcancel", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const handleQuizExpandedChange = (event: CustomEvent<QuizExpandEvent>) => {
      setIsQuizExpanded(event.detail.quizExpanded);
      setIsMobile(event.detail.isMobile);
    };

    window.addEventListener(
      "quizExpandedChange",
      handleQuizExpandedChange as EventListener,
    );

    return () => {
      window.removeEventListener(
        "quizExpandedChange",
        handleQuizExpandedChange as EventListener,
      );
    };
  }, []);

  useEffect(() => {
    const handleQuizOptionSelection = (
      event: CustomEvent<QuizOptionSelectedEvent>,
    ) => {
      setIsQuizOptionSelected(event.detail.optionSelected);
      setIsMobile(event.detail.isMobile);
    };

    window.addEventListener(
      "quizOptionSelected",
      handleQuizOptionSelection as EventListener,
    );

    return () => {
      window.removeEventListener(
        "quizOptionSelected",
        handleQuizOptionSelection as EventListener,
      );
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0 && !hasScrolled) {
      setHasScrolled(true);
      setAnimationClass("translate-y-0 opacity-100");
    }
  }, [hasScrolled]);

  useEffect(() => {
    if (!isClientSide || isInfiniteScrollArticle) return;

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isInfiniteScrollArticle, handleScroll]);

  useEffect(() => {
    const handleAnimationEnd = () => {
      setAnimationClass("");
    };

    const element = buttonRef.current;
    if (element) {
      element.addEventListener("transitionend", handleAnimationEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener("transitionend", handleAnimationEnd);
      }
    };
  }, []);

  return (
    <div
      data-testid={`draggable-cta-${id}`}
      ref={buttonRef}
      className={`
        flex gap-1 items-center w-fit h-[34px]
        fixed z-[51] transition-all duration-500 right-1 lg:right-[32%]
        ${isDragging ? "shadow-slider" : ""}
        ${visibleArticleId === id ? "visible" : "invisible"}
        ${hasScrolled ? animationClass : "translate-y-[100%] opacity-0"}
      `}
      style={{
        ...(position
          ? { left: `${position.x}px`, top: `${position.y}px` }
          : {
              bottom: isQuizExpanded
                ? isQuizOptionSelected
                  ? isMobile
                    ? "250px"
                    : "150px"
                  : isMobile
                    ? "205px"
                    : "95px"
                : "62px",
            }),
        touchAction: "none",
      }}
    >
      <button
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        className="absolute z-[52] px-2 py-3 cursor-move"
      />
      {parseInt(ARTICLE_TRANSLATE_CTA) ? (
        <TranslationCTA
          article={translatedArticle}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
          pageNumber={isInfiniteScrollArticle ? pageNumber : 0}
        />
      ) : null}
      {parseInt(ARTICLE_SUMMARY_CTA) ? (
        <AISummaryCTA
          article={translatedArticle}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
          pageNumber={isInfiniteScrollArticle ? pageNumber : 0}
        />
      ) : null}
    </div>
  );
}
