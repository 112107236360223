import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import LabelCardIconNew from "@components/HomePageStoryPackages/Label/LabelCardIconNew";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import ShareSocial, {
  type SocialVariant,
} from "@elements/ShareSocial/ShareSocial";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { RefObject } from "react";
import { twMerge } from "tailwind-merge";

import PremiumVideoPlayer from "./PremiumVideoPlayer";

type CardStyles = {
  wrapperClass?: string;
  nameClass?: string;
  descriptionClass?: string;
  dateClass?: string;
};

type Props = {
  playing: LatestVideoResponse;
  forwadedRef: RefObject<HTMLDivElement>;
  onEnded?: () => void;
  nextPlaylist?: () => void;
  showDescription?: boolean;
  cardStyle?: CardStyles;
  variant?: "video" | "webinar";
};

const socialIconList = ["facebook", "telegram", "whatsapp"];

export default function VideoPlayer({
  playing,
  forwadedRef,
  onEnded,
  nextPlaylist,
  showDescription = false,
  cardStyle = {},
  variant = "video",
}: Props) {
  const {
    id,
    name,
    topStoryDate,
    description,
    published_at,
    webinar_time,
    isPremium,
  } = playing;
  const { wrapperClass, nameClass, descriptionClass, dateClass } = cardStyle;
  return (
    <>
      {isPremium && variant === "webinar" ? (
        <PremiumVideoPlayer
          playing={playing}
          forwadedRef={forwadedRef}
          onEnded={onEnded}
          nextPlaylist={nextPlaylist}
        />
      ) : (
        <BrightcoveVideo
          videoId={id}
          forwardedRef={forwadedRef}
          onEnded={onEnded}
          nextPlaylist={nextPlaylist}
        />
      )}
      <div className="flex flex-col font-secondary">
        <h3
          className={twMerge(
            "font-semimedium text-[28px] leading-xl text-black-300 my-xs",
            nameClass,
          )}
        >
          {name}
        </h3>
        {showDescription && (
          <span
            className={twMerge(
              "my-2 hidden lg:block text-xxs text-semimedium lg:text-xs text-grey-400",
              descriptionClass,
            )}
          >
            {description}
          </span>
        )}
        <div
          className={twMerge("flex items-center justify-between", wrapperClass)}
        >
          <span
            className={twMerge(
              "text-xxs text-semimedium lg:text-sm text-grey-400",
              dateClass,
            )}
          >
            {variant === "webinar" ? (
              <>
                {isPremium && (
                  <LabelCardIconNew
                    contentAccess={LabelCardIconVariety.PREMIUM}
                    className="mr-2"
                  />
                )}
                {published_at}{" "}
                <span className="font-thin">- {webinar_time}</span>
              </>
            ) : (
              topStoryDate
            )}
          </span>
          <div className="flex gap-2">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={`videos/${id}`}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
