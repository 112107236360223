import { SECONDARY_SECTION_LIST_NEW } from "@components/HeaderNew/headerMenuConstants";
import { HeaderMenuList } from "@components/HeaderNew/HeaderMenuList";
import { SHOW_SUB_CATEGORIES } from "@src/app/helper/getEnvVariables";
import { type ReactElement } from "react";

export type SecondaryLinksProps = Readonly<{
  testId?: string;
}>;

export function SecondaryLinks({
  testId = "desktop-secondary-links",
}: SecondaryLinksProps): ReactElement {
  return (
    <div
      data-testid={`${testId}-secondaryLinks`}
      className={`hidden lg:flex justify-end ${parseInt(SHOW_SUB_CATEGORIES) ? "lg:w-[700px] xl:w-[800px]" : "lg:w-[840px] xl:w-[915px]"}`}
    >
      <div data-testid={`${testId}-secondaryLinksInner`}>
        <HeaderMenuList
          testId={testId}
          items={SECONDARY_SECTION_LIST_NEW}
          type="secTopics"
        />
      </div>
    </div>
  );
}
