/**
 * Company: SPHMedia
 * Description: [GE ST widet]
 */
import { ReactElement } from "react";

/**
 * GESTWidget
 * Description: [GE ST widget (https://uat.straitstimes.com/multimedia/graphics/uat/proj-2402-ge-results/bh-widget.html)]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GESTWidget({
  geSTWidgetContent,
}: {
  geSTWidgetContent: string;
}): ReactElement {
  return (
    <div className="border border-grey-250">
      <div dangerouslySetInnerHTML={{ __html: geSTWidgetContent }} />
    </div>
  );
}
