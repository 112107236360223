export enum AdvertisementTypeEnum {
  LB1 = "lb1",
  LB3 = "lb3",
  LB2 = "lb2",
  IMU1 = "imu1",
  IMU2 = "imu2",
  IMU3 = "imu3",
  MIDARTICLESPECIAL = "midarticlespecial",
  PRESTITIAL = "prestitial",
  CATFISH = "catfish",
  BOTTOMSPECIAL = "bottomspecial",
}

export type AdUnitProps = {
  rootClassName?: string;
  type: AdvertisementTypeEnum;
  slot: string;
  id?: string;
  pubAdTargeting?:
    | AdTargetingType[]
    | AdTargetingType<string, PageAdTargetValue>[];
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

export enum ArticleAdTargetKeys {
  ARTICLE_ID = "bharticleid",
  TAGS = "bhtags",
  PAGE = "page",
}

export type PageAdTargetValue =
  | PageAdTargetingTypeEnum.HOME
  | PageAdTargetingTypeEnum.LISTING
  | PageAdTargetingTypeEnum.TAG
  | PageAdTargetingTypeEnum.ARTICLE
  | PageAdTargetingTypeEnum.TRANSLATION
  | PageAdTargetingTypeEnum.SUMMARY_EN
  | PageAdTargetingTypeEnum.SUMMARY_MS;

export type AdTargetingType<K = string, T = string | string[]> = {
  key: K;
  value: T;
};

export type CompanionAdUnitProps = AdUnitProps & {
  adSlotSize: googletag.GeneralSize | undefined;
  pageNumber: string;
  applySizeMapping?: boolean;
};

export enum PageAdTargetingTypeEnum {
  HOME = "home",
  LISTING = "listing",
  TAG = "tag",
  ARTICLE = "article",
  TRANSLATION = "translation",
  SUMMARY_EN = "summary_en",
  SUMMARY_MS = "summary_ms",
}
